import React, { useState } from 'react'
import image from "../assests/images/depo-img.jpeg"

const DepoInformation = () => {
    const [disabledTab1, setdisabledTab1] = useState(true);
    const [disabledTab2, setdisabledTab2] = useState(true);
    const [editDepoName, setEditDepoName] = useState(true);
    const [editDepoAddress, setEditDepoAddress] = useState(true);
    const [editDepoType, setEditDepoType] = useState(true);
    const [editWorkingHours, setEditWorkingHours] = useState(true);

    const modalExecutiveEdit = () => {
        document.body.classList.toggle("dp-executive-edit-open");
    }
    const modalExecutiveAdd = () => {
        document.body.classList.toggle("dp-executive-add-open");
    }
    const modalExecutiveDelete = () => {
        document.body.classList.toggle("dp-executive-delete-open");
    }
    const modalExecutiveGallery = () => {
        document.body.classList.toggle("dp-executive-gallery-open");
    }
    const modalExecutiveDeleteImage = () => {
        document.body.classList.toggle("dp-executive-delete-image-open");
    }

    const depoDetailsTab = (evt, tab) => {
        console.log("tab: " + tab);
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-pane");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("nav-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tab).style.display = "block";
        evt.currentTarget.className += " active";
    }


    return (
        <>
            <div className="container-fluid pt-4 px-4">
                <div className="row g-4">
                    <div className="col-sm-12">
                        <div className="bg-secondary rounded h-100 p-4">
                            <div className='row dp-feature-sticky'>
                                <ul className="nav nav-pills col-sm-10 " id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-home-tab" onClick={(e) => depoDetailsTab(e, 'tab1')}>Genel Bilgiler</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-profile-tab" onClick={(e) => depoDetailsTab(e, 'tab2')}>Galeri</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content mt-3" id="pills-tabContent">
                                <div className="tab-pane active show fade pill" id="tab1">
                                    <div className='row g-4'>
                                        <div className='row g-4'>
                                            <div className='d-flex col align-items-center' >

                                                <h5 className=''>Genel Depo Bilgileri</h5>

                                            </div>
                                        </div>

                                        <div className='h-100 p-4 pt-0 col-md-6'>
                                            <div className="form-check form-check-inline w-100">
                                                <h6 className='edit-title'>Depo İsmi<i className="far fa-edit edit-icon" onClick={() => setEditDepoName(current => !current)}></i></h6>
                                                {editDepoName === true ? <p>Levent Kolektif Depo</p> : <div className="input-group mb-3">
                                                    <input type="text" className="form-control" />
                                                    <button className="input-group-button btn btn-info">Kaydet</button>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className='h-100 p-4 pt-0 col-md-6'>
                                            <div className="form-check form-check-inline w-100">
                                                <h6 className='edit-title'>Depo Adresi<i className="far fa-edit edit-icon" onClick={() => setEditDepoAddress(current => !current)}></i></h6>
                                                {editDepoAddress === true ? <p>Levent Kolektif Depo Adresi</p> : <div className="input-group mb-3">
                                                    <input type="text" className="form-control" />
                                                    <button className="input-group-button btn btn-info">Kaydet</button>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className='h-100 p-4 pt-0 col-md-6'>
                                            <div className="form-check form-check-inline w-100">
                                                <h6 className='edit-title'>Depo Tipi<i className="far fa-edit edit-icon" onClick={() => setEditDepoType(current => !current)}></i></h6>
                                                {editDepoType === true
                                                    ? <p>Antrepo</p>
                                                    : <div className="input-group mb-3">
                                                        <select className="form-select">
                                                            <option value="1">Depo Tipini Seçiniz</option>
                                                            <option value="2">Depo</option>
                                                            <option value="3">Antrepo</option>
                                                        </select>
                                                        <button className="input-group-button btn btn-info">Kaydet</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='h-100 p-4 pt-0 col-md-6'>
                                            <div className="form-check form-check-inline w-100">
                                                <h6 className='edit-title'>Çalışma Saatleri<i className="far fa-edit edit-icon" onClick={() => setEditWorkingHours(current => !current)}></i></h6>
                                                {editWorkingHours === true
                                                    ?
                                                    <table className='working-hours-table'>
                                                        <tr>
                                                            <td>Hafta İçi:</td>
                                                            <td>09:00 / 18:00</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Hafta Sonu:</td>
                                                            <td>10:00 / 15:00</td>
                                                        </tr>
                                                    </table>
                                                    : <>
                                                        <table className='working-hours-table'>
                                                            <tr>
                                                                <td>Hafta İçi:</td>
                                                                <td>
                                                                    <input type="time" className="form-control" />
                                                                </td>
                                                                <td>/</td>
                                                                <td>
                                                                    <input type="time" className="form-control" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hafta Sonu:</td>
                                                                <td>
                                                                    <input type="time" className="form-control" />
                                                                </td>
                                                                <td>/</td>
                                                                <td>
                                                                    <input type="time" className="form-control" />
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <button className="input-group-button btn btn-info">Kaydet</button>
                                                    </>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row g-4'>
                                        <div className='row g-4'>
                                            <div className='d-flex col align-items-center' >
                                                <h5>Depo Yetkilisi</h5>
                                            </div>
                                            <div className=' d-flex col align-items-center justify-content-end' >
                                                <button type="button" className="btn btn-info" onClick={modalExecutiveAdd}>Yeni Yetkili Ekle</button>
                                            </div>
                                        </div>
                                        <div className='row justify-content-center'>
                                            <div className='col-xl-4 m-4 bg-secondary rounded position-relative dp-executive-card'>
                                                <div className="dp-executive-delete"><i className="far fa-edit" onClick={modalExecutiveEdit}></i><i className="fas fa-trash-alt" onClick={modalExecutiveDelete}></i></div>
                                                <div className="form-check form-check-inline w-100 mt-4">
                                                    <h6>Yetkili İsmi</h6>
                                                    <p>Yiğitcan Derya</p>
                                                </div>
                                                <div className="form-check form-check-inline w-100">
                                                    <h6>Yetkili Telefonu</h6>
                                                    <p>0555 555 55 55</p>
                                                </div>
                                                <div className="form-check form-check-inline w-100">
                                                    <h6>Yetkili E-Posta Adresi</h6>
                                                    <p>yigitcan@llcsoft.com</p>
                                                </div>
                                                <div className="form-check form-check-inline w-100">
                                                    <h6>Sorumlu Olduğu Bölge</h6>
                                                    <p>Türkiye</p>
                                                </div>
                                            </div>
                                            <div className='col-xl-4 m-4 bg-secondary rounded position-relative dp-executive-card'>
                                                <div className="dp-executive-delete"><i className="far fa-edit" onClick={modalExecutiveEdit}></i><i className="fas fa-trash-alt" onClick={modalExecutiveDelete}></i></div>
                                                <div className="form-check form-check-inline w-100 mt-4">
                                                    <h6>Yetkili İsmi</h6>
                                                    <p>Yiğitcan Derya</p>
                                                </div>
                                                <div className="form-check form-check-inline w-100">
                                                    <h6>Yetkili Telefonu</h6>
                                                    <p>0555 555 55 55</p>
                                                </div>
                                                <div className="form-check form-check-inline w-100">
                                                    <h6>Yetkili E-Posta Adresi</h6>
                                                    <p>yigitcan@llcsoft.com</p>
                                                </div>
                                                <div className="form-check form-check-inline w-100">
                                                    <h6>Sorumlu Olduğu Bölge</h6>
                                                    <p>Türkiye</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane show fade pill" id="tab2">

                                    <div className='row g-4'>
                                        <div className='row g-4 mb-3'>
                                            <div className='d-flex col align-items-center' >
                                                <h5 className=''>Depo Görselleri</h5>
                                            </div>

                                            <div className=' d-flex col align-items-center justify-content-end' >
                                                <button type="button" className="btn btn-info" onClick={modalExecutiveGallery}>Yeni Görsel Ekle</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row g-4'>
                                        <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6 col-xl-3">
                                            <div className='d-flex position-relative dp-executive-card'>

                                                <div className="dp-executive-delete"><i className="fas fa-times" onClick={modalExecutiveDeleteImage}></i></div>
                                                <img className='w-100 shadow' src={image}></img>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6 col-xl-3">
                                            <div className='d-flex position-relative dp-executive-card'>

                                                <div className="dp-executive-delete"><i className="fas fa-times" onClick={modalExecutiveDeleteImage}></i></div>
                                                <img className='w-100 shadow' src={image}></img>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6 col-xl-3">
                                            <div className='d-flex position-relative dp-executive-card'>

                                                <div className="dp-executive-delete"><i className="fas fa-times" onClick={modalExecutiveDeleteImage}></i></div>
                                                <img className='w-100 shadow' src={image}></img>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6 col-xl-3">
                                            <div className='d-flex position-relative dp-executive-card'>

                                                <div className="dp-executive-delete"><i className="fas fa-times" onClick={modalExecutiveDeleteImage}></i></div>
                                                <img className='w-100 shadow' src={image}></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>

            <div className="dp-executive-edit-background" onClick={modalExecutiveEdit}></div>
            <div className="dp-executive-edit-modal text-center">
                <h3>Yetkili Bilgilerini Güncelleyin</h3>
                <span onClick={modalExecutiveEdit} className="dp-executive-edit-modal-close">X</span>

                <div className="row mb-3 mt-4 justify-content-center">
                    <label className="col-md-4 col-form-label">İsim Soyisim:</label>
                    <div className="col-md-6">
                        <input type="text" className="form-control col-md-6" />
                    </div>
                </div>
                <div className="row mb-3 mt-4 justify-content-center">
                    <label className="col-md-4 col-form-label">Telefon:</label>
                    <div className="col-md-6">
                        <input type="phone" className="form-control col-md-6" />
                    </div>
                </div>
                <div className="row mb-3 mt-4 justify-content-center">
                    <label className="col-md-4 col-form-label">E-Posta Adresi:</label>
                    <div className="col-md-6">
                        <input type="email" className="form-control col-md-6" />
                    </div>
                </div>
                <div className="row mb-3 mt-4 justify-content-center">
                    <label className="col-md-4 col-form-label">Sorumlu Olduğu Bölge:</label>
                    <div className="col-md-6">
                        <input type="text" className="form-control col-md-6" />
                    </div>
                </div>

                <button type="button" className="btn btn-info m-2">Güncelle</button>
                <button type="button" className="btn btn-outline-info m-2" onClick={modalExecutiveEdit}>İptal Et</button>
            </div>


            <div className="dp-executive-add-background" onClick={modalExecutiveAdd}></div>
            <div className="dp-executive-add-modal text-center">
                <h3>Yeni Depo Yetkilisi Ekleyin</h3>
                <span onClick={modalExecutiveAdd} className="dp-executive-add-modal-close">X</span>

                <div className="row mb-3 mt-4 justify-content-center">
                    <label className="col-md-4 col-form-label">İsim Soyisim:</label>
                    <div className="col-md-6">
                        <input type="text" className="form-control col-md-6" />
                    </div>
                </div>
                <div className="row mb-3 mt-4 justify-content-center">
                    <label className="col-md-4 col-form-label">Telefon:</label>
                    <div className="col-md-6">
                        <input type="phone" className="form-control col-md-6" />
                    </div>
                </div>
                <div className="row mb-3 mt-4 justify-content-center">
                    <label className="col-md-4 col-form-label">E-Posta Adresi:</label>
                    <div className="col-md-6">
                        <input type="email" className="form-control col-md-6" />
                    </div>
                </div>
                <div className="row mb-3 mt-4 justify-content-center">
                    <label className="col-md-4 col-form-label">Sorumlu Olduğu Bölge:</label>
                    <div className="col-md-6">
                        <input type="text" className="form-control col-md-6" />
                    </div>
                </div>

                <button type="button" className="btn btn-info m-2">Ekle</button>
                <button type="button" className="btn btn-outline-info m-2" onClick={modalExecutiveAdd}>İptal Et</button>
            </div>


            <div className="dp-executive-delete-background" onClick={modalExecutiveDelete}></div>
            <div className="dp-executive-delete-modal text-center">
                <h3>Depo Yetkilisini Silin</h3>
                <span onClick={modalExecutiveDelete} className="dp-executive-delete-modal-close">X</span>
                <p>Depo yetkilisini silmek istediğinize emin misiniz?</p>

                <button type="button" className="btn btn-info m-2"><i className='fas fa-trash-alt'></i> Sil</button>
                <button type="button" className="btn btn-outline-info m-2" onClick={modalExecutiveDelete}>İptal Et</button>
            </div>


            <div className="dp-executive-gallery-background" onClick={modalExecutiveGallery}></div>
            <div className="dp-executive-gallery-modal text-center">
                <h3>Yeni Fotoğraf Ekleyin</h3>
                <span onClick={modalExecutiveGallery} className="dp-executive-gallery-modal-close">X</span>
                <p>Kullanıcıların deponuzu daha detaylı inceleyebilmesi için yeni fotoğraflar ekleyebilirisniz.</p>

                <button type="button" className="btn btn-info m-2"><i className='fas fa-trash-alt'></i> Sil</button>
                <button type="button" className="btn btn-outline-info m-2" onClick={modalExecutiveGallery}>İptal Et</button>
            </div>


            <div className="dp-executive-delete-image-background" onClick={modalExecutiveDeleteImage}></div>
            <div className="dp-executive-delete-image-modal text-center">
                <h3>Fotoğrafı Sil</h3>
                <span onClick={modalExecutiveDeleteImage} className="dp-executive-delete-image-modal-close">X</span>
                <p>Depo fotoğrafını silmek istediğinize emin misiniz?</p>

                <button type="button" className="btn btn-info m-2"><i className='fas fa-trash-alt'></i> Sil</button>
                <button type="button" className="btn btn-outline-info m-2" onClick={modalExecutiveDeleteImage}>İptal Et</button>
            </div>

        </>
    )
}

export default DepoInformation