import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useSiteContext } from '../context/SettingsContext';
import { ref, set, onValue, remove, update, query, orderByChild, equalTo } from "firebase/database";
import { database } from '../firebase';


const Wallet = () => {
    const [disabledTab1, setdisabledTab1] = useState(true);
    const [disabledTab2, setdisabledTab2] = useState(true);
    const itemsPerPage = 10;
    const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

    const { selectedDepoId, setSelectedDepoId, user, setUser } = useSiteContext();
    const [selectedUser, setSelectedUser] = useState();
    const [userWallet, setUserWallet] = useState();


    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    useEffect(() => {
        if (selectedDepoId === undefined) {
            setSelectedDepoId(localStorage.getItem("depoSelected"));
        }
        if (user === undefined) {
            setUser(localStorage.getItem("userId"));
        }
    }, []);
    useEffect(() => {
        if (user) {
            const filteredUser = query(ref(database, "users"), orderByChild("id"), equalTo(user));
            onValue(filteredUser, (snapshot) => {
                let data_ = snapshot.val();
                let _data_ = Object.keys(data_).map(key => {
                    return data_[key];
                });
                setSelectedUser(_data_);
                if (_data_[0].wallet) {
                    let wallet = Object.keys(_data_[0].wallet).map(key => {
                        return _data_[0].wallet[key];
                    })
                    setUserWallet(wallet);
                    console.log(wallet[0]);
                }
            });
        }
    }, [user]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    const addCashModal = () => {
        document.body.classList.toggle("dp-add-cash-open");
    }
    const depoDetailsTab = (evt, tab) => {
        console.log("tab: " + tab);
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-pane");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("nav-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tab).style.display = "block";
        evt.currentTarget.className += " active";
    }

    return (
        <>
            <div className="container-fluid pt-4 px-4">
                <div className="bg-secondary rounded h-100 p-4">
                    <div className='row'>
                        <div className='col-xl-6 align-self-center'>
                            Güncel Bakiye: <b> {userWallet && userWallet[0]} ₺</b>
                        </div>
                        <div className='col-xl-6 text-end'>
                            <button type='button' className="btn btn-info" onClick={addCashModal}><i className='fa fa-plus'></i> Bakiye Ekle</button>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>İşlem Id</th>
                                    <th>Kiralama No</th>
                                    <th>Tarih</th>
                                    <th>Tutar</th>
                                    <th>Yeni Bakiye</th>
                                    <th>Önceki Bakiye</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>#005326</td>
                                    <td>3912</td>
                                    <td>18.07.2023</td>
                                    <td>500₺</td>
                                    <td>1750₺</td>
                                    <td>1250₺</td>
                                </tr>
                                <tr>
                                    <td>#005326</td>
                                    <td>3912</td>
                                    <td>18.07.2023</td>
                                    <td>500₺</td>
                                    <td>1750₺</td>
                                    <td>1250₺</td>
                                </tr>
                                <tr>
                                    <td>#005326</td>
                                    <td>3912</td>
                                    <td>18.07.2023</td>
                                    <td>500₺</td>
                                    <td>1750₺</td>
                                    <td>1250₺</td>
                                </tr>
                                <tr>
                                    <td>#005326</td>
                                    <td>3912</td>
                                    <td>18.07.2023</td>
                                    <td>500₺</td>
                                    <td>1750₺</td>
                                    <td>1250₺</td>
                                </tr>
                                <tr>
                                    <td>#005326</td>
                                    <td>3912</td>
                                    <td>18.07.2023</td>
                                    <td>500₺</td>
                                    <td>1750₺</td>
                                    <td>1250₺</td>
                                </tr>
                                <tr>
                                    <td>#005326</td>
                                    <td>3912</td>
                                    <td>18.07.2023</td>
                                    <td>500₺</td>
                                    <td>1750₺</td>
                                    <td>1250₺</td>
                                </tr>
                                <tr>
                                    <td>#005326</td>
                                    <td>3912</td>
                                    <td>18.07.2023</td>
                                    <td>500₺</td>
                                    <td>1750₺</td>
                                    <td>1250₺</td>
                                </tr>
                                <tr>
                                    <td>#005326</td>
                                    <td>3912</td>
                                    <td>18.07.2023</td>
                                    <td>500₺</td>
                                    <td>1750₺</td>
                                    <td>1250₺</td>
                                </tr>
                                <tr>
                                    <td>#005326</td>
                                    <td>3912</td>
                                    <td>18.07.2023</td>
                                    <td>500₺</td>
                                    <td>1750₺</td>
                                    <td>1250₺</td>
                                </tr>
                            </tbody>
                        </table>
                        <nav aria-label="Page navigation example" className='mt-3'>
                            <ul className="pagination justify-content-end">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Sonraki"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel="Önceki"
                                    renderOnZeroPageCount={null}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>


            <div className="dp-add-cash-background" onClick={addCashModal}></div>
            <div className="dp-add-cash-modal text-center">
                <h3>Bakiye Yükle</h3>
                <span onClick={addCashModal} className="dp-add-cash-modal-close">X</span>

                <div className='row dp-feature-sticky'>
                    <ul className="nav nav-pills col-sm-10 " id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" onClick={(e) => depoDetailsTab(e, 'tab1')}>Kredi Kartı</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" onClick={(e) => depoDetailsTab(e, 'tab2')}>Havale/EFT</button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content mt-3" id="pills-tabContent">
                    <div className="tab-pane active show fade pill" id="tab1">
                        <p>Aşağıdaki link üzerinden ödemenizi yaptıktan sonra bakiye tanımlamanız için formu doldurunuz.</p>
                        <p><a href="#" className='iyzico-link'>İyzico Ödeme Linki</a></p>
                        <div className="row mb-3 text-start mt-5">
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                <p>Dekont Belgesi</p>
                                <input className="form-control bg-dark" type="file" id="formFile"></input>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                <p>Hesap Sahibinin Adı Soyadı</p>
                                <input type="text" className="form-control" id="inputEmail3"></input>
                            </div>
                        </div>
                        <div className="row mb-3 text-start">
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                <p>İşlem Tutarı</p>
                                <input type="number" className="form-control" id="inputEmail3"></input>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                <p>İşlem Tarihi</p>
                                <input type="date" className="form-control" id="inputEmail3"></input>
                            </div>
                        </div>
                        <button type="button" className="btn btn-info m-2">Yükleme Yap</button>
                    </div>

                    <div className="tab-pane show fade pill" id="tab2">
                        <h6>Havale/EFT</h6>
                        <p><b>Banka Adı: </b>Türkiye İş Bankası</p>
                        <p><b>İban: </b>TR00 0000 0000 0000 0000 0000 00</p>

                        <div className="row mb-3 text-start mt-5">
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                <p>Dekont Belgesi</p>
                                <input className="form-control bg-dark" type="file" id="formFile"></input>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                <p>Hesap Sahibinin Adı Soyadı</p>
                                <input type="text" className="form-control" id="inputEmail3"></input>
                            </div>
                        </div>
                        <div className="row mb-3 text-start">
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                <p>İşlem Tutarı</p>
                                <input type="number" className="form-control" id="inputEmail3"></input>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                <p>İşlem Tarihi</p>
                                <input type="date" className="form-control" id="inputEmail3"></input>
                            </div>
                        </div>
                        <button type="button" className="btn btn-info m-2">Yükleme Yap</button>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Wallet