import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const SiteContext = createContext();
export const useSiteContext = () => useContext(SiteContext);

const SettingsContext = ({ children }) => {
    const [filterSelected, setFilterSelected] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [sidebarIsActive, setSidebarIsActive] = useState(true);
    const [isActive, setIsActive] = useState();
    const [selectedDepoId, setSelectedDepoId] = useState();
    const [user, setUser] = useState();
    const [dates, setDates] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [filtredDepo, setFiltredDepo] = useState();
    const [i_volume, setI_volume] = useState();
    const [i_productAttribute, setI_productAttribute] = useState();
    const [i_city, setI_city] = useState();
    const [areaType, setAreaType] = useState();
    const [servicesType, setServicesType] = useState();
    const [i_town, setI_town] = useState();
    const [selectedDepoName, setSelectedDepoName] = useState();


    const navigate = useNavigate();


    const handleTabClicked = (tab) => {
        setActiveTab(tab);
    };

    const handleSidebarActive = () => {
        setSidebarIsActive(true);
    };

    // const mainMenu = (e) => {
    //     let sidebar = document.getElementById('depo_sidebar');
    //     let content = document.getElementById('depo_content');
    //     sidebar.classList.add('open')
    //     content.classList.add('open')
    //     setIsActive("a");
    //     navigate("/");

    // }

    useEffect(() => {
        if (localStorage.getItem("userType") != "2") {
            navigate("/logout");
        }
    }, []);

    const data = {
        i_town, setI_town, servicesType, setServicesType, areaType, setAreaType, i_city, setI_city, i_productAttribute, setI_productAttribute, i_volume, setI_volume, filterSelected, setFilterSelected, endDate, setEndDate, startDate, setStartDate, dates, setDates, activeTab, sidebarIsActive, setActiveTab, setSidebarIsActive, handleTabClicked, handleSidebarActive, isActive, setIsActive, setSelectedDepoId, selectedDepoId, user, setUser, filtredDepo, setFiltredDepo, selectedDepoName, setSelectedDepoName
    }

    return (
        <SiteContext.Provider value={data} >
            {children}
        </SiteContext.Provider>
    )
}

export default SettingsContext;