import React, { useEffect, useState } from 'react'
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { ref, set, onValue, remove, update, query, orderByChild, equalTo } from "firebase/database";
import { database } from '../firebase';
import { useSiteContext } from '../context/SettingsContext';


const Questions = () => {

    const { user, setUser, } = useSiteContext();
    const [answer, setAnswer] = useState(true);
    const [answerText, setAnswerText] = useState();
    const [selectedDepo, setSelectedDepo] = useState();
    const [questionsData, setQuestionsData] = useState();
    const [questions, setQuestions] = useState();



    const dummyData = [{ name: "Yiğit Can Derya", question: "Lorem ipsum dolor sit amet", answer: "Delectus sit perferendis" }, { name: "Musa Alioğlu", question: "Lorem ipsum dolor sit amet", answer: "Delectus sit perferendis" }, { name: "Salih Lüleci", question: "Lorem ipsum dolor sit amet", answer: "Delectus sit perferendis" }, { name: "Mehmet Mert Özkar", question: "Lorem ipsum dolor sit amet", answer: "Delectus sit perferendis" }]

    useEffect(() => {
        if (user === undefined) {
            setUser(localStorage.getItem("userId"));
        }
    }, [])
    useEffect(() => {
        if (user) {
            const filteredUser = query(ref(database, "questions"), orderByChild("userId"), equalTo(user));
            onValue(filteredUser, (snapshot) => {
                let data_ = snapshot.val();
                let _data_ = Object.keys(data_).map(key => {
                    return data_[key];
                });
                setQuestions(_data_);
                // Yanıtlanmış sorular 0
                // Yanıtlanmamış sorular 1
                // Onay Bekleyen sorular 2
                // Reddedilen sorular 3
            });
        }


    }, [user]);

    const depoQuestionsTab = (evt, tab) => {
        console.log("tab: " + tab);
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-pane");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("nav-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tab).style.display = "block";
        evt.currentTarget.className += " active";
    }

    return (
        <>


            <div className="container-fluid pt-4 px-4">
                <div className="row g-4">
                    <div className="col-12">
                        <div className="bg-secondary rounded h-100 p-4">
                            <h5 className="mb-4">Depo Soruları</h5>


                            <div className='row dp-feature-sticky'>
                                <ul className="nav nav-pills col-sm-10 " id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-home-tab" onClick={(e) => depoQuestionsTab(e, 'tab1')}>Yanıtlanmamış Sorular</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-profile-tab" onClick={(e) => depoQuestionsTab(e, 'tab2')}>Yanıtlanmış Sorular</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-profile-tab" onClick={(e) => depoQuestionsTab(e, 'tab3')}>Onay Bekleyen Sorular</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-profile-tab" onClick={(e) => depoQuestionsTab(e, 'tab4')}>Reddedilmiş Sorular</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content mt-3" id="pills-tabContent">
                                <div className="tab-pane active show fade pill" id="tab1">
                                    {questions?.filter(q => q.status === "1").map((question, key) => (<>
                                        <div className='row g-4 mt-3 p-4 pt-0 bg-secondary rounded dp-comment'>
                                            <h6># {key + 1} </h6>
                                            <p className='mt-0'>{question.question}</p>
                                            <div className='row g-4 mt-1 p-4 pt-0 pb-0 dp-comment'>
                                                <h6>Yanıt: Depo Sahibi</h6>
                                                <p className='mt-0'>{question.answer}</p>
                                            </div>
                                        </div>
                                    </>))}
                                </div>

                                <div className="tab-pane show fade pill" id="tab2">
                                    {questions?.filter(q => q.status === "0").map((question, key) => (<>
                                        <div className='row g-4 mt-3 p-4 pt-0 bg-secondary rounded dp-comment'>
                                            <h6># {key + 1} </h6>
                                            <p className='mt-0'>{question.question}</p>
                                            <div className='row g-4 mt-1 p-4 pt-0 pb-0 dp-comment'>
                                                <h6>Yanıt: Depo Sahibi</h6>
                                                <p className='mt-0'>{question.answer}</p>
                                            </div>
                                        </div>
                                    </>))}
                                </div>

                                <div className="tab-pane show fade pill" id="tab3">
                                    {questions?.filter(q => q.status === "2").map((question, key) => (<>
                                        <div className='row g-4 mt-3 p-4 pt-0 bg-secondary rounded dp-comment'>
                                            <h6># {key + 1} </h6>
                                            <p className='mt-0'>{question.question}</p>
                                            <div className='row g-4 mt-1 p-4 pt-0 pb-0 dp-comment'>
                                                <h6>Yanıt: Depo Sahibi</h6>
                                                <p className='mt-0'>{question.answer}</p>
                                            </div>
                                        </div>
                                    </>))}
                                </div>

                                <div className="tab-pane show fade pill" id="tab4">
                                    {questions?.filter(q => q.status === "3").map((question, key) => (<>
                                        <div className='row g-4 mt-3 p-4 pt-0 bg-secondary rounded dp-comment'>
                                            <h6># {key + 1} </h6>
                                            <p className='mt-0'>{question.question}</p>
                                            <div className='row g-4 mt-1 p-4 pt-0 pb-0 dp-comment'>
                                                <h6>Yanıt: Depo Sahibi</h6>
                                                <p className='mt-0'>{question.answer}</p>
                                            </div>
                                        </div>
                                    </>))}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Questions