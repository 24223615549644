import React, { useEffect, useState } from 'react'
import { ref, set, onValue, remove, update, query, orderByChild, equalTo } from "firebase/database";
import { database } from '../firebase';
import { useSiteContext } from '../context/SettingsContext';
import { useNavigate } from 'react-router-dom';
import cityData from './citySelect.json'


const Search = () => {

    const { i_town, setI_town, servicesType, setServicesType, areaType, setAreaType, i_city, setI_city, i_productAttribute, setI_productAttribute, i_volume, setI_volume, dates, setDates, endDate, setEndDate, startDate, setStartDate, filtredDepo, setFiltredDepo, filterSelected, setFilterSelected } = useSiteContext();

    const [tabSelected, setTabSelected] = useState("tab1");
    const [depos, setDepos] = useState();
    const [editInputInfo, setEditInputInfo] = useState({
        city: "", town: ""
    });

    const navigate = useNavigate();

    const servicesTab = (evt, tab) => {
        console.log("tab: " + tab);
        setTabSelected(tab);
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-pane");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("nav-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tab).style.display = "block";
        evt.currentTarget.className += " active";
    }

    useEffect(() => {
        const filteredUser = query(ref(database, "depos"));
        onValue(filteredUser, (snapshot) => {
            let data_ = snapshot.val();
            let _data_ = Object.keys(data_).map(key => {
                return data_[key];
            })
            setDepos(_data_);
        });
        localStorage.getItem("volume") === null ? <></> : setI_volume(localStorage.getItem("volume"));
        localStorage.getItem("city") === null ? <></> : setI_city(localStorage.getItem("city"));
        localStorage.getItem("town") === null ? <></> : setI_town(localStorage.getItem("town"));
        localStorage.getItem("servicesType") === null ? <></> : setServicesType(localStorage.getItem("servicesType"));
        localStorage.getItem("areaType") === null ? <></> : setAreaType(localStorage.getItem("areaType"));
        localStorage.getItem("productAttribute") === null ? <></> : setI_productAttribute(localStorage.getItem("productAttribute"));
        localStorage.getItem("startDate") === null ? <></> : setStartDate(localStorage.getItem("startDate"));
        localStorage.getItem("endDate") === null ? <></> : setEndDate(localStorage.getItem("endDate"));
    }, [filterSelected]);


    const findDepo = () => {
        const filteredDepos = depos.filter(x => (parseInt(x.maxVolume) - parseInt(x.currentVolume)) >= i_volume).filter(x => x.city === i_city).filter(x => x.town === i_town).filter(x => x.servicesType === servicesType).filter(x => x.depoType === areaType).filter(x => x.productAttribute.find(x => x === i_productAttribute));
        const filteredDeposId = [];
        filteredDepos.map((depo) => {
            filteredDeposId.push(depo.id)
        })
        console.log(filteredDeposId.length)
        if (filteredDeposId.length === 0) {
            localStorage.setItem("filteredDeposId", 0);

        } else {
            localStorage.setItem("filteredDeposId", filteredDeposId);

        }
        localStorage.setItem("volume", i_volume);
        localStorage.setItem("city", i_city);
        localStorage.setItem("town", i_town);
        localStorage.setItem("servicesType", servicesType);
        localStorage.setItem("areaType", areaType);
        localStorage.setItem("productAttribute", i_productAttribute);
        localStorage.setItem("startDate", startDate);
        localStorage.setItem("endDate", endDate);
        navigate("/result");
        setFilterSelected(current => !current)
    }




    return (
        <>

            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">
                <div className="bg-secondary text-center rounded p-4">
                    <div className="d-flex align-items-center mt-4 text-start row">
                        <div className="mb-3 col-xs-6 col-sm-6 col-lg-6 col-md-6 col-xl-4">
                            <label className="form-label">İşlem Hacmi (m<sup>3</sup>)</label>
                            <input type="number" className="form-control" value={i_volume} onChange={(e) => setI_volume(e.target.value)} />
                        </div>
                        <div className="mb-3 col-xs-6 col-sm-6 col-lg-6 col-md-6 col-xl-4">
                            <label className="form-label">İl</label>
                            <select className="form-select" onChange={(e) => setI_city(e.target.value)} >
                                {localStorage.getItem("city") === null ? <option value="1">Şehir Seçiniz</option> : <option value={i_city}> {i_city} </option>}
                                {cityData.map((city, i) =>
                                    <option value={city.il} > {city.il}  </option>)}
                            </select>
                        </div>
                        <div className="mb-3 col-xs-6 col-sm-6 col-lg-6 col-md-6 col-xl-4">
                            <label className="form-label">İlçe</label>
                            <select className="form-select" onChange={(e) => setI_town(e.target.value)} >
                                {localStorage.getItem("town") === null ? <option value="1">İlçe Seçiniz</option> : <option value={i_town}> {i_town} </option>}
                                {cityData.find(c => c.il === i_city)?.ilceleri?.map((city, i) =>
                                    <option value={city} > {city}  </option>)}
                            </select>
                        </div>
                        <div className="mb-3 col-xs-6 col-sm-6 col-lg-6 col-md-6 col-xl-4">
                            <label className="form-label">Hizmet Kategorisi</label>
                            <select className="form-select" aria-label="Hizmet Kategorisi Seçiniz" onChange={(e) => setServicesType(e.target.value)} >
                                {localStorage.getItem("servicesType") === null ? <option value="1">Hizmet Kategorisi Seçiniz</option> : <option value={servicesType}> {servicesType} </option>}
                                <option value="Depolama">Depolama</option>
                                <option value="Fulfillment">Fulfillment</option>
                            </select>
                        </div>
                        <div className="mb-3 col-xs-6 col-sm-6 col-lg-6 col-md-6 col-xl-4">
                            <label className="form-label">Alan Türü</label>
                            <select className="form-select" onChange={(e) => setAreaType(e.target.value)} >

                                {localStorage.getItem("areaType") === null ? <option value="1">Alan Türünü Seçiniz</option> : <option value={areaType}> {areaType} </option>}

                                <option value="Soğuk Hava Deposu">Soğuk Hava Deposu</option>
                                <option value="Sıcak Hava Deposu">Sıcak Hava Deposu</option>
                                <option value="Açık Alan Deposu">Açık Alan Deposu</option>
                            </select>
                        </div>
                        <div className="mb-3 col-xs-6 col-sm-6 col-lg-6 col-md-6 col-xl-4">
                            <label className="form-label">Ürün Niteliği</label>
                            <select className="form-select" aria-label="Ürün Niteliğini Seçiniz" value={i_productAttribute} onChange={(e) => setI_productAttribute(e.target.value)}>
                                <option selected="">Ürün Niteliğini Seçiniz</option>
                                <option value="Normal Ürün">Normal Ürün</option>
                                <option value="Tehlikeli Ürün">Tehlikeli Ürün</option>
                                <option value="Askılı Ürün">Askılı Ürün</option>
                            </select>
                        </div>
                        {/* <div className="mb-3 col-xs-6 col-sm-6 col-lg-6 col-md-6 col-xl-4">
                            <label className="form-label">Başlangıç Tarihi</label>
                            <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </div>
                        <div className="mb-3 col-xs-6 col-sm-6 col-lg-6 col-md-6 col-xl-4">
                            <label className="form-label">Bitiş Tarihi</label>
                            <input type="date" className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </div> */}

                    </div>
                    <div className=" d-flex col align-items-center justify-content-center mt-3">
                        <button type="button" className="btn btn-info" disabled={!i_volume || !i_city || !i_town || !i_productAttribute || !servicesType || !areaType} onClick={findDepo} >Uygun Depo Bul</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Search