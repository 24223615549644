import React from 'react'
import Home from './Home'
import Sidebar from './Sidebar'
import classNames from 'classnames';
import { useSiteContext } from '../context/SettingsContext';
import { Route, Routes } from 'react-router-dom';
import Rent from './Rent';
import DepoDetails from './DepoDetails';
import Payments from './Payments';
import Questions from './Questions';
import QuestionDetail from './QuestionDetail';
import RentDetails from './RentDetails';
import TopBar from './TopBar';
import DepoInformation from './DepoInformation';
import AlbumTestPage from './AlbumTestPage';
import Login from './Login';
import Result from './Result';
import Logout from './Logout';
import Wallet from './Wallet';
import Profile from './Profile';
import { Toaster } from 'react-hot-toast'
import Register from './Register';

const Content = () => {
    const { sidebarIsActive } = useSiteContext();

    return (
        <>
            {/* {window.location.pathname === "/login" ? null : <Sidebar />} */}
            {/* <Sidebar /> */}
            <div className="content open" id='depo_content'>
                {/* <TopBar /> */}
                {window.location.pathname === "/login" ? null : <TopBar />}
                <Toaster position="top-right"
                    toastOptions={{
                        style: {
                            zIndex: '99999999999',
                        }
                    }}
                />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/result" element={<Result />} />
                    <Route path="/depo-details/:name" element={<DepoDetails />} />
                    <Route path="/depo-information" element={<DepoInformation />} />
                    <Route path="/payments" element={<Payments />} />
                    <Route path="/questions" element={<Questions />} />
                    <Route path="/question-detail" element={<QuestionDetail />} />
                    <Route path="/rent" element={<Rent />} />
                    <Route path="/rent-details" element={<RentDetails />} />
                    <Route path="/album-test-page" element={<AlbumTestPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/wallet" element={<Wallet />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path='/register' element={<Register />} />
                </Routes>
            </div>

        </>

    )
}

export default Content