import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useSiteContext } from '../context/SettingsContext';
import { ref, set, onValue, remove, update, query, orderByChild, equalTo } from "firebase/database";
import { deleteObject, ref as refe, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { database, storage } from '../firebase';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';

const DepositSchema = Yup.object().shape({
    accountName: Yup.string()
        .min(2, 'Girilen Değer Çok Kısa!')
        .max(50, 'Girilen Değer Çok Uzun!')
        .required('*'),
    amount: Yup.number().required('*'),
});

const Profile = () => {
    const [userName, setUserName] = useState(true);
    const [userMail, setUserMail] = useState(true);
    const [userPhone, setUserPhone] = useState(true);
    const [paymentType, setPaymentType] = useState("iyzico");

    const [imgURL_, setImgURL_] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [imageAsset, setImageAsset] = useState(null);
    const [msg, setMsg] = useState(null);
    const [fields, setFields] = useState(false);
    const [alertStatus, setAlertStatus] = useState("danger");

    const [disabledTab1, setdisabledTab1] = useState(true);
    const [disabledTab2, setdisabledTab2] = useState(true);
    const itemsPerPage = 10;

    const { selectedDepoId, setSelectedDepoId, user, setUser } = useSiteContext();
    const [selectedUser, setSelectedUser] = useState();
    const [userWallet, setUserWallet] = useState();
    const [userRents, setUserRents] = useState();
    const [transactions, setTransactions] = useState();


    const [itemOffset, setItemOffset] = useState(0);


    useEffect(() => {
        if (selectedDepoId === undefined) {
            setSelectedDepoId(localStorage.getItem("depoSelected"));
        }
        if (user === undefined) {
            setUser(localStorage.getItem("userId"));
        }
    }, []);
    useEffect(() => {
        if (user) {
            const filteredUser = query(ref(database, "users"), orderByChild("id"), equalTo(user));
            onValue(filteredUser, (snapshot) => {
                let data_ = snapshot.val();
                if (data_ != undefined) {
                    let _data_ = Object.keys(data_).map(key => {
                        return data_[key];
                    });
                    setSelectedUser(_data_);
                    if (_data_[0].wallet) {
                        let wallet = Object.keys(_data_[0].wallet).map(key => {
                            return _data_[0].wallet[key];
                        })
                        setUserWallet(wallet);
                    }
                    if (_data_[0].rents) {
                        let rents = Object.keys(_data_[0].rents).map(key => {
                            return _data_[0].rents[key];
                        })
                        setUserRents(rents);
                    }
                } else {
                    setSelectedUser(false);
                }
            });
            const userTransactions = query(ref(database, "transactions"), orderByChild("userId"), equalTo(user));
            onValue(userTransactions, (snapshot) => {
                let data_ = snapshot.val();
                if (data_ != undefined) {
                    let _data_ = Object.keys(data_).map(key => {
                        return data_[key];
                    });
                    setTransactions(_data_);
                } else {
                    setSelectedUser(false);
                }
            });
        }
    }, [user]);

    // Paginate
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = transactions && transactions.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(transactions && transactions.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % transactions && transactions.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    const addCashModal = () => {
        document.body.classList.toggle("dp-add-cash-open");
    }
    const depoDetailsTab = (evt, tab) => {
        tab === "tab1" ? setPaymentType("iyzico") : setPaymentType("Havale");
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-pane");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("nav-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tab).style.display = "block";
        evt.currentTarget.className += " active";
    }

    const uploadImage = (e) => {
        setIsLoading(true);
        const imageFile = e.target.files[0];
        const storageRef = refe(storage, `paymentDocuments/${user}/paymentDocuments/${Date.now()}`);
        const uploadTask = uploadBytesResumable(storageRef, imageFile);

        uploadTask.on('state_changed', (snapshot) => {
            const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        }, (error) => {
            console.log(error);
            setFields(true);
            setMsg('Error while uploading : Try Again🙇‍♂️');
            setAlertStatus('danger');
            setTimeout(() => {
                setFields(false);
                setIsLoading(false);
            }, 4000)
        }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                setImageAsset(downloadURL);
                setIsLoading(false);
                setFields(true);
                setImgURL_(downloadURL)
                setAlertStatus("success");
                setTimeout(() => {
                    setFields(false);
                }, 4000);

            })
        });
    }
    const deleteImage = () => {
        setIsLoading(true);
        const deleteRef = refe(storage, imageAsset);
        deleteObject(deleteRef).then(() => {
            setImageAsset(null);
            setIsLoading(false);
            setFields(true);
            setTimeout(() => {
                setFields(false);
            }, 4000);
        });
        setImgURL_("");
    }


    return (
        <>
            <div className="container-fluid pt-4 px-4">
                <div className="bg-secondary rounded h-100 p-4">
                    <div className='row mb-5'>
                        <h5>Profil Bilgileri</h5>
                    </div>
                    <div className='row g-4'>

                        <div className='h-100 p-4 pt-0 col-md-4'>
                            <div className="form-check form-check-inline w-100">
                                <h6 className='edit-title'>İsim Soyisim<i className="far fa-edit edit-icon" onClick={() => setUserName(current => !current)}></i></h6>
                                {userName === true ? <p>  {selectedUser && selectedUser[0].name}  </p> : <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Yiğit Can Derya" />
                                    <button className="input-group-button btn btn-info">Kaydet</button>
                                </div>}
                            </div>
                        </div>
                        <div className='h-100 p-4 pt-0 col-md-4'>
                            <div className="form-check form-check-inline w-100">
                                <h6 className='edit-title'>E-Posta Adresi<i className="far fa-edit edit-icon" onClick={() => setUserMail(current => !current)}></i></h6>
                                {userMail === true ? <p> {selectedUser && selectedUser[0].email} </p> : <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="yigit@depoglobal.com" />
                                    <button className="input-group-button btn btn-info" >Kaydet</button>
                                </div>}
                            </div>
                        </div>
                        <div className='h-100 p-4 pt-0 col-md-4'>
                            <div className="form-check form-check-inline w-100">
                                <h6 className='edit-title'>Telefon <i className="far fa-edit edit-icon" onClick={() => setUserPhone(current => !current)}></i></h6>
                                {userPhone === true ? <p>  {selectedUser && selectedUser[0].phone}  </p> : <div className="input-group mb-3">
                                    <input type="number" className="form-control" placeholder="0555 555 55 55" />
                                    <button className="input-group-button btn btn-info" >Kaydet</button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-secondary rounded h-100 p-4 mt-5">
                    <div className='row'>
                        <div className='col-xl-6 align-self-center'>
                            Güncel Bakiye: <b> {userWallet && userWallet[0]} ₺</b>
                        </div>
                        <div className='col-xl-6 text-end'>
                            <button type='button' className="btn btn-info" onClick={addCashModal}><i className='fa fa-plus'></i> Bakiye Ekle</button>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>İşlem Id</th>
                                    <th>Tarih</th>
                                    <th>Tutar</th>
                                    <th>Ödeme Yöntemi</th>
                                    <th>Durum</th>
                                    <th>Yüklenen Dosya</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems?.map((transaction, i) => <>
                                    <tr>
                                        <td>#{transaction.id.substr(0, 4)} </td>
                                        <td> {transaction.paymentDate} </td>
                                        <td>{transaction.amount}₺</td>
                                        <td>{transaction.paymentType}</td>
                                        <td>{transaction.status}</td>
                                        <td> <a type='button' className='btn btn-primary' href={transaction.documentURL} target="popup" onClick={() => window.open(transactions.documentURL, 'popup', 'width=600, height=600')}>Görüntüle</a> </td>
                                    </tr></>)}
                            </tbody>
                        </table>
                        <nav aria-label="Page navigation example" className='mt-3'>
                            <ul className="pagination justify-content-end">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Sonraki"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel="Önceki"
                                    renderOnZeroPageCount={null}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>


            <div className="dp-add-cash-background" onClick={addCashModal}></div>
            <div className="dp-add-cash-modal text-center">
                <h3>Bakiye Yükle</h3>
                <span onClick={addCashModal} className="dp-add-cash-modal-close">X</span>

                <div className='row dp-feature-sticky'>
                    <ul className="nav nav-pills col-sm-10 " id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" onClick={(e) => depoDetailsTab(e, 'tab1')}>Kredi Kartı</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" onClick={(e) => depoDetailsTab(e, 'tab2')}>Havale/EFT</button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content mt-3" id="pills-tabContent">
                    <div className="tab-pane active show fade pill" id="tab1">
                        <p>Aşağıdaki link üzerinden ödemenizi yaptıktan sonra bakiye tanımlamanız için formu doldurunuz.</p>
                        <p><a href="#" className='iyzico-link'>İyzico Ödeme Linki</a></p>
                    </div>

                    <div className="tab-pane show fade pill" id="tab2">
                        <h6>Havale/EFT</h6>
                        <p><b>Banka Adı: </b>Türkiye İş Bankası</p>
                        <p><b>İban: </b>TR00 0000 0000 0000 0000 0000 00</p>

                    </div>
                </div>
                <div className="row mb-3 text-start mt-5">
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-5'>
                        <p>Dekont Belgesi</p>
                        {isLoading ? <><h3>Loading</h3></> : (<>
                            {!imageAsset ? (<>
                                <label className='w-full h-full flex flex-col items-center justify-center cursor-pointer'>
                                    <input type="file" name="upload" onChange={uploadImage}
                                        className="form-control bg-dark" id="formFile" />
                                </label>
                            </>) :
                                (<> <div className='relative h-20 '>
                                    <a type='button' className='btn btn-primary' href={imageAsset} target="blank"> Görüntüle </a>
                                    <button type='button' className="btn btn-outline-info m-2" onClick={deleteImage}>
                                        Sil
                                    </button>
                                </div>
                                </>)}
                        </>)}
                    </div>

                </div>
                <Formik
                    initialValues={{
                        accountName: '',
                        amount: '',
                    }}
                    validationSchema={DepositSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        const randomId = nanoid(7);
                        const paymentDate = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().toString().split(" ")[2] + "-" + new Date().toString().split(" ")[4];
                        set(ref(database, "transactions/" + randomId), {
                            id: randomId,
                            userId: user && user,
                            userName: selectedUser && selectedUser[0].name,
                            status: "Onay Bekliyor",
                            amount: values.amount,
                            paymentDate: paymentDate && paymentDate,
                            accountName: values.accountName,
                            paymentType: paymentType && paymentType,
                            documentURL: imgURL_ ? imgURL_ : null
                        })
                        resetForm();
                        setSubmitting(false);
                        setImageAsset(null);
                        setImgURL_("");
                        document.body.classList.toggle("dp-add-cash-open");
                    }}

                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-3 text-start">
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                    <p>Hesap Sahibinin Adı Soyadı {errors.accountName && touched.accountName && errors.accountName}</p>
                                    <input
                                        name='accountName'
                                        type="text"
                                        className="form-control"
                                        id="inputEmail3"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.accountName}
                                    />
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                    <p>İşlem Tutarı {errors.amount && touched.amount && errors.amount}</p>
                                    <input
                                        name='amount'
                                        type="number"
                                        className="form-control"
                                        id="inputEmail3"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.amount}
                                    />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-info m-2" disabled={isSubmitting}>Yükleme Yap</button>
                        </form>)}
                </Formik>
            </div>
        </>
    )
}

export default Profile