import React from "react";
import LoginService from "./LoginService";


const Login = () => {

    return (
        <>
            <div class="container-fluid">
                <div class="row h-100 align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                    <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                        <LoginService />
                    </div>
                </div>
            </div>
        </>

    );
};

export default Login;
