import React, { useState, useEffect } from 'react'
import depoImgage from '../assests/images/depo-img.jpeg'
import { useSiteContext } from '../context/SettingsContext'
import { useNavigate } from 'react-router-dom';
import { ref, set, onValue, remove, update, query, orderByChild, equalTo } from "firebase/database";
import { database } from '../firebase';
import Search from './Search'

const Result = () => {

    const { setI_volume, setI_city, setI_town, setSelectedDepoName, selectedDepoName, selectedDepo, setSelectedDepo, setSelectedDepoId, selectedDepoId, filtredDepo, setFiltredDepo, filterSelected, setFilterSelected } = useSiteContext();
    const [data, setData] = useState();
    const [location, setLocation] = useState();
    const [selectedExecutives, setSelectedExecutives] = useState();
    const [finalData_, setFinalData_] = useState();
    const [dates, setDates,] = useState();
    const navigate = useNavigate();



    const selectDepo = (e, y) => {
        localStorage.setItem("depoSelected", e);
        navigate(`/depo-details/${y}`);
        setSelectedDepoId(e);
        setSelectedDepoName(y)

    }

    const resultİtemActive = (location, id) => {
        setLocation(location);
        var btns = document.getElementsByClassName("result-item");
        for (var i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function () {
                var current = document.getElementsByClassName("result-item-active");
                if (current.length > 0) {
                    current[0].className = current[0].className.replace(" result-item-active", "");
                }
                this.className += " result-item-active";
            });
        }
        const activeDepo = query(ref(database, "depos"), orderByChild("id"), equalTo(id));
        onValue(activeDepo, (snapshot) => {
            let data_ = snapshot.val();
            let _data_ = Object.keys(data_).map(key => {
                return data_[key];
            })
            if (_data_[0].depoExecutives) {
                let executives = Object.keys(_data_[0].depoExecutives).map(key => {
                    return _data_[0].depoExecutives[key];
                })
                setSelectedExecutives(executives);
            }
        })
    }

    useEffect(() => {
        const allDepos = query(ref(database, "depos"));
        onValue(allDepos, (snapshot) => {
            let data_ = snapshot.val();
            let _data_ = Object.keys(data_).map(key => {
                return data_[key];
            })
            setData(_data_);
        })

    }, []);

    useEffect(() => {
        const finalData = [];
        const oneId = localStorage.getItem("filteredDeposId").split(",")[0];
        if (data) {
            if (Number(localStorage.getItem("filteredDeposId")) === 0) {
                setFinalData_("");
            } else {
                if (localStorage.getItem("filteredDeposId").split(",").length === 1) {
                    finalData.push(data && data.filter(d => d.id == oneId)[0]);
                    setFinalData_(finalData);
                } else {
                    data && data.map((data, i) => {
                        if (data.id == localStorage.getItem("filteredDeposId").split(",")[i]) {
                            finalData.push(data);
                            setFinalData_(finalData);
                        }
                    })
                }
            }
        }

    }, [localStorage.getItem("filteredDeposId"), data])



    return (
        <>
            <div className="container-fluid pt-4 px-4">

                <div className="row g-4 mb-3">
                    <div className="col-xs-12 col-sm-6 col-lg-4 col-md-6 col-xl-6">
                        <p>Arama kriterlerinize göre <span> {finalData_ && finalData_.length} {finalData_ == "" ? "0" : null} </span> sonuç bulunmuştur.</p>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-lg-4 col-md-6 col-xl-6">
                        <div class=" d-flex col align-items-center justify-content-end mr-1">
                            <button type="button" class="btn btn-info" onClick={() => setFilterSelected(current => !current)}>Filtrele</button>
                        </div>
                    </div>
                    {!filterSelected === true ? <Search /> : <></>}


                </div>

                <div className="row">
                    <div className="col-lg-6 result-list">
                        {finalData_ && finalData_?.map((depo, index) => <>
                            <div className="row bg-secondary rounded mb-3 cursor-pointer result-item" onClick={() => resultİtemActive(depo.map, depo.id)}>
                                <div className="col-xs-12 col-sm-12 col-lg-4 col-md-4 col-xl-4 pl-0">
                                    <img className='w-100 shadow' src={depo.featuredImage}></img>
                                </div>
                                <div className='col-xs-12 col-sm-12 col-lg-8 col-md-8 col-xl-8'>
                                    <div className="row d-flex align-items-center justify-content-between mt-2 mr-1 text-start">
                                        <h6 className="mb-0 col-8"> {depo.name} </h6>
                                        <button type="button" className="btn btn-outline-info col-4" onClick={() => selectDepo(depo.id, depo.name)} >Görüntüle</button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-2 text-start row">
                                        <h6 className="mb-0 col-12">Adres: {depo.location}</h6>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-2 text-start row">
                                        {/* <h6 className="mb-0 col-12">Hacim: {depo.currentVolume} m<sup>3</sup> / {depo.maxVolume} m<sup>3</sup> <span className='solidityRatio' >(% {((depo.currentVolume / depo.maxVolume) * 100).toFixed(2)} )</span>  </h6> */}
                                        <h6 className="mb-0 col-12"> Günlük m<sup>3</sup> fiyatı {depo.price}₺ </h6>
                                    </div>
                                </div>
                            </div>

                        </>)}
                    </div>
                    <div className="col-lg-6 position-relative sm-display-none">
                        <div className=' position-absolute result-contact-card p-3'>
                            <h6>İletişim Bilgileri</h6>
                            <p className='mb-0'><b>Depo Yetkilisi: </b> {selectedExecutives && selectedExecutives[0].name} </p>
                            <p className='mb-0'><b>Telefon: </b>{selectedExecutives && selectedExecutives[0].phone}</p>
                            <p><b>Eposta: </b>{selectedExecutives && selectedExecutives[0].mail}</p>
                        </div>
                        <iframe src={location} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='result-map'></iframe>
                    </div>


                </div>
            </div>
            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>

        </>
    )
}

export default Result