import React, { useState } from "react";
import logoBlue from '../assests/images/logo_mavi1.png'

const RegisterService = () => {

    const [i_passwordLogin, setI_passwordLogin] = useState();
    const [i_usernameLogin, setI_usernameLogin] = useState();
    const [showPass, setShowPass] = useState(false);

    return (
        <>
            <div className="bg-secondary rounded p-4 p-sm-5 my-4 mx-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <img src={logoBlue} />
                    <h5>Müşteri Kayıt</h5>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-sm-12">
                        <div className="form-floating mb-3">
                            <input type="text" style={{ marginRight: "20px" }} className="form-control" id="floatingInput" placeholder="name@example.com" />
                            <label >İsim</label>
                        </div>
                    </div>
                    <div className="col-xl-6 col-sm-12">
                        <div className="form-floating mb-3">
                            <input type="text" style={{ marginRight: "20px" }} className="form-control" id="floatingInput" placeholder="name@example.com" />
                            <label >Soyisim</label>
                        </div>
                    </div>
                </div>
                <div className="form-floating mb-3">
                    <input type="email" style={{ marginRight: "20px" }} className="form-control" id="floatingInput" placeholder="name@example.com" />
                    <label >E-Posta</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="phone" style={{ marginRight: "20px" }} className="form-control" id="floatingInput" placeholder="name@example.com" />
                    <label >Telefon</label>
                </div>
                <div className="form-floating mb-3">
                    <input type={showPass ? "text" : "password"} value={i_passwordLogin} onChange={(e) => setI_passwordLogin(e.target.value)} className="form-control" id="floatingPassword" placeholder="Password" />
                    <label htmlFor="floatingPassword">Şifre</label>
                    <span style={{ display: "flex", justifyContent: "end", position: "absolute", right: "10px", bottom: "20px", width: "10px" }} onClick={() => setShowPass(current => !current)}> {showPass ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>
                    }</span>
                </div>
                <div className="form-check mb-4">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    <label className="form-check-label" for="exampleCheck1">Kişisel verilerimin, siparişlerimi işlemek, bu web sitesindeki deneyimimi desteklemek ve <a href="#" className="dp-secondary-link">gizlilik ilkesinde</a> açıklanan diğer amaçlar için kullanılmasını onaylıyor ve kabul ediyorum.</label>
                </div>
                <button className="btn btn-primary py-3 w-100 mb-4" >Kayıt Ol</button>
                {/* <p className="text-center mb-0">Don't have an Account? <a href="">Sign Up</a></p> */}
            </div>
        </>
    )
}

export default RegisterService