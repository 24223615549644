import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import classNames from 'classnames';
import { useSiteContext } from '../context/SettingsContext';
import { ref, set, onValue, remove, update, query, orderByChild, equalTo } from "firebase/database";
import { database } from '../firebase';



const TopBar = () => {
    const [data, setData] = useState();
    const [data__, setData__] = useState();
    const [user, setUser] = useState();

    const navigate = useNavigate();

    const toggleMenuHandler = () => {
        let sidebar = document.getElementById('depo_sidebar');
        let content = document.getElementById('depo_content');
        sidebar.classList.toggle('open')
        content.classList.toggle('open')
    }
    const toggleNotificationHandler = () => {
        let notification = document.getElementById('depo_notification');
        let notification_2 = document.getElementById('depo_notification_2');
        notification.classList.toggle('show')
        notification_2.classList.toggle('show')
    }
    const toggleProfileHandler = () => {
        let dp_profile = document.getElementById('dp_profile');
        let dp_profile_2 = document.getElementById('dp_profile_2');
        dp_profile.classList.toggle('show')
        dp_profile_2.classList.toggle('show')
    }

    const pageRedirect = (url, dropdown) => {
        console.log("pageRedirect: " + url)
        if (dropdown === "profile") {
            toggleProfileHandler();
        } else if (dropdown === "notification") {
            toggleNotificationHandler();
        }
        navigate(url);
    }

    // useEffect(() => {
    //     const notifications = query(ref(database, "users/hE1WuXEkvSfKtt-vKmYp3/notifications",));
    //     onValue(notifications, (snapshot) => {
    //         let data_ = snapshot.val();
    //         let _data_ = Object.keys(data_).map(key => {
    //             return data_[key];
    //         })
    //         let data__ = _data_.filter(key => key.status == 1);
    //         setData(data__);
    //     })

    useEffect(() => {
        const selectedUser = query(ref(database, "users/" + localStorage.getItem("userId")));
        onValue(selectedUser, (snapshot) => {
            let data_ = snapshot.val();
            setUser(data_);

        })
        console.log("tekrar render?")
    }, [localStorage.getItem("userId")])

    const seenHandler = (e) => {
        update(ref(database, "users/hE1WuXEkvSfKtt-vKmYp3/notifications/" + e), { status: 0 })
    }

    return (
        <>
            <nav className="navbar navbar-expand bg-secondary navbar-dark sticky-top px-4 py-0">
                {/* {window.location.pathname === "/" ? null : <a className="sidebar-toggler flex-shrink-0" style={{ cursor: "pointer" }} onClick={toggleMenuHandler}>
                    <i className="fa fa-bars"></i>
                </a>} */}
                <div className="navbar-brand mx-3 mt-2">
                    <Link to={"/"}> <h3 className="text-primary">DepoGlobal</h3></Link>
                </div>
                <div className="navbar-nav align-items-center ms-auto">
                    {!user && <div className="nav-item ml-1">
                        <Link to={"/login"} > <button type="button" class="btn btn-outline-info">Giriş Yap</button></Link>
                    </div>}
                    {/* <div className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" onClick={toggleNotificationHandler} id='depo_notification' >
                            <i className="fa fa-bell me-lg-2"></i>
                            <span className="d-none d-lg-inline-flex">Bildirimler <span className='notification' style={data && data?.length == "0" || data === undefined ? { display: "none" } : null} > {data && data?.length} </span></span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end bg-secondary border-0 rounded-0 rounded-bottom m-0 " id="depo_notification_2" >
                            {data && data.map((noti, key) =>
                                <div key={key} >
                                    <a className="dropdown-item" onMouseOver={() => seenHandler(noti.id)} >
                                        <h6 className="fw-normal mb-0"> {noti.text} </h6>
                                        <small>15 minutes ago</small>
                                    </a>
                                    <hr className="dropdown-divider" />
                                </div>
                            )}
                        </div>
                    </div> */}
                    <div className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" onClick={toggleProfileHandler} id='dp_profile'>
                            <span className=" d-lg-inline-flex"> {user && user.name} </span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end bg-secondary border-0 rounded-0 rounded-bottom m-0 r-0" id='dp_profile_2'>
                            <a className="dropdown-item" onClick={() => pageRedirect("/profile", "profile")}>Profil & Cüzdan</a>
                            <a className="dropdown-item" onClick={() => pageRedirect("/rent", "profile")}>Depo İşlemleri</a>
                            <a className="dropdown-item" onClick={() => pageRedirect("/questions", "profile")}>Sorular</a>
                            <a className="dropdown-item" onClick={() => pageRedirect("/logout", "profile")} >Çıkış Yap</a>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default TopBar