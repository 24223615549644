import React, { useEffect, useState } from "react";
import { ref, set, onValue, remove, update, query, orderByChild, equalTo } from "firebase/database";
import bcrypt from 'bcryptjs'
import { database } from '../firebase';
import { Link, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';


const LoginService = () => {

    const [i_passwordLogin, setI_passwordLogin] = useState();
    const [i_usernameLogin, setI_usernameLogin] = useState();
    const [showPass, setShowPass] = useState(false);

    const navigate = useNavigate();

    const loginHandler = (e) => {
        e.preventDefault();
        const filteredUser_ = query(ref(database, "users"), orderByChild("username"), equalTo(i_usernameLogin));
        onValue(filteredUser_, (snapshot) => {
            let data_ = snapshot.val();
            if (data_ === null) {
                toast.error("Hesap Bulunamadı");
                setI_usernameLogin("");
                setI_passwordLogin("");
            } else {
                let _data_ = Object.keys(data_).map(key => {
                    return data_[key];
                })
                if (_data_[0].userType === "2") {
                    const password = i_passwordLogin;
                    const savedHash = _data_[0].pass;
                    bcrypt.compare(password, savedHash, function (err, result) {
                        if (result === true) {
                            toast.success("Giriş Başarılı");
                            setI_usernameLogin("");
                            setI_passwordLogin("");
                            if (window.location.pathname === "/depo-details") {
                                navigate("/depo-details")
                            } else {
                                navigate("/")
                            }
                            localStorage.setItem("userId", _data_[0].id);
                            localStorage.setItem("userType", _data_[0].userType);
                        } else {
                            toast.error("Hatalı Şifre");
                            setI_usernameLogin("");
                            setI_passwordLogin("");
                        }
                    });
                } else {
                    toast.error("Hesap Bulunamadı");
                }

            }
        });
    }
    useEffect(() => {
        if (localStorage.getItem("userId")) {
            navigate("/");
        }
    }, [])
    return (
        <>
            <div class="bg-secondary rounded p-4 p-sm-5 my-4 mx-3">
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <Link to={"/"} >
                        <a class="">
                            <h3 class="text-primary">Depoglobal</h3>
                        </a>
                    </Link>

                    <h5>Kullanıcı Girişi</h5>
                </div>
                <div class="form-floating mb-3">
                    <input type="text" value={i_usernameLogin} onChange={(e) => setI_usernameLogin(e.target.value)} style={{ marginRight: "20px" }} class="form-control" id="floatingInput" />
                    <label >Kullanıcı Adı</label>
                </div>
                <div class="form-floating mb-4">
                    <input type={showPass ? "text" : "password"} value={i_passwordLogin} onChange={(e) => setI_passwordLogin(e.target.value)} class="form-control" id="floatingPassword" placeholder="Password" />
                    <label for="floatingPassword">Şifre</label>
                    <span style={{ display: "flex", justifyContent: "end", position: "absolute", right: "10px", bottom: "20px", width: "10px" }} onClick={() => setShowPass(current => !current)}> {showPass ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>
                    }</span>
                </div>

                <div class="d-flex align-items-center justify-content-between mb-4">
                    {/* <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                    <label class="form-check-label" for="exampleCheck1">Check me out</label>
                                </div> */}
                    <a href="">Şifremi Unuttum</a>
                </div>
                <button class="btn btn-primary py-3 w-100 mb-4" onClick={loginHandler} >Giriş Yap</button>
                <Link to={"/register"} className="btn btn-outline-primary py-3 w-100 mb-4" >Kayıt Ol</Link>
                {/* <p class="text-center mb-0">Don't have an Account? <a href="">Sign Up</a></p> */}
            </div>
        </>

    );
};

export default LoginService;
