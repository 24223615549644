import React, { useEffect, useState, useRef } from 'react'
import bcrypt from 'bcryptjs'
import { ref, set, onValue, remove, update, query, orderByChild, equalTo } from "firebase/database";
import { database } from '../firebase';
import { nanoid, random } from 'nanoid';

const AlbumTestPage = () => {

    const [dummyUser, setDummyUser] = useState();
    const [dummyData, setDummyData] = useState([{ userName: "123", password: "1234" }, { userName: "12345", password: "123456" }]);
    const [userData, setUserData] = useState([])
    const [i_passwordSingup, setI_passwordSingup] = useState();
    const [i_usernameSingup, setI_usernameSingup] = useState();
    const [i_passwordLogin, setI_passwordLogin] = useState();
    const [i_passwordLogin_, setI_passwordLogin_] = useState();
    const [i_usernameLogin, setI_usernameLogin] = useState();
    const [i_usernameLogin_, setI_usernameLogin_] = useState();
    const [showPass, setShowPass] = useState(false);
    const [data, setData] = useState([]);
    const [arrayData, setArrayData] = useState();
    const [removedId, setRemovedId] = useState();
    const [randomId, setRandomId] = useState(nanoid());
    const [currentData_, setCurrentData_] = useState();


    const inputRef = useRef(null);
    const saltRounds = 10;

    // write
    // set(ref(database, 'deneme/' + "1234512335"), { username: "name", email: "test@test.com", profile_picture: "imgURL" });

    // read
    // let arrayData;

    const singupHandler = () => {
        const password = i_passwordSingup;
        console.log(password)
        bcrypt.hash(password, saltRounds, function (err, hash) {
            console.log(hash)
            set(ref(database, 'users/' + randomId), { username: i_usernameSingup, pass: hash, id: randomId });
        });
        console.log("User has been created!");
        setRandomId(nanoid());
        resetForm();
    }

    const loginHandler = (e) => {
        e.preventDefault();
        // const filteredUser = query(ref(database, "users"), orderByChild("username"), equalTo(i_usernameLogin));
        // onValue(filteredUser, (snapshot) => {
        //     let data_ = snapshot.val();
        //     let _data_ = Object.keys(data_).map(key => {
        //         return data_[key];
        //     })
        //     setData(_data_);
        // });
        const filteredUser = query(ref(database, "users"), orderByChild("username"), equalTo(i_usernameLogin));
        onValue(filteredUser, (snapshot) => {
            let data_ = snapshot.val();
            let _data_ = Object.keys(data_).map(key => {
                return data_[key];
            })
            // setData(_data_); 
            const userName = i_usernameLogin;
            const password = i_passwordLogin;
            if (!_data_) {
                console.log("Kullanıcı Bulunamadı.");
                setI_usernameLogin("");
                setI_passwordLogin("");
            } else {
                const savedHash = _data_[0].pass;
                bcrypt.compare(password, savedHash, function (err, result) {
                    if (result === true) {
                        console.log("tebrikler") // doğru şifre
                        setI_usernameLogin("");
                        setI_passwordLogin("");
                    } else {
                        console.log("giremen guzum giremen") // yanlış şifre
                        setI_usernameLogin("");
                        setI_passwordLogin("");
                    }
                });
            }
        });
    }

    const removeDatabase = (x) => {
        remove(ref(database, "users/" + x));
        console.log(x)

    }

    const updateDatabase = (x) => {
        let currentVar;
        const currentData = query(ref(database, "users/" + x));
        onValue(currentData, (snapshot) => {
            const data = snapshot.val();
            currentVar = data;
        })
        update(ref(database, "users/" + x), { notification: currentVar.notification + 2 })
    }



    useEffect(() => {
        const allUsers = query(ref(database, "users"));
        onValue(allUsers, (snapshot) => {
            let data_ = snapshot.val();
            let _data_ = Object.keys(data_).map(key => {
                return data_[key];
            })
            setData(_data_);
        })

    }, [])



    console.log(data);
    const resetForm = () => {
        setI_passwordSingup("");
        setI_usernameSingup("");
    }

    //update
    // update(ref(database, 'deneme/'), { email: "test12@test.com" })

    //remove
    // remove(ref(database, "deneme12"));



    // write

    return (
        <>
            <div style={{ marginLeft: "20px" }}>
                <div className='mt-5'>

                    {/* <h3 className=''>Data Deneme</h3>
                    <button type="button" style={{ marginLeft: "20px" }} className="btn btn-info" onClick={dataHandler}> Data çek
                    </button> */}
                </div>

                <h2 className='mt-5'>User Karşılaştırması</h2>

                <h3 className='mt-5'>Kayıt Et</h3>
                <label style={{ marginRight: "5px" }}>Username:</label>
                <input type="text" value={i_usernameSingup} onChange={(e) => setI_usernameSingup(e.target.value)} style={{ marginRight: "20px" }} />
                <label style={{ marginRight: "5px" }}>Password:</label>
                <input type={showPass ? "text" : "password"} value={i_passwordSingup} onChange={(e) => setI_passwordSingup(e.target.value)} />
                <span onClick={() => setShowPass(current => !current)}> {showPass ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>
                }</span>
                <button type="button" style={{ marginLeft: "20px" }} className="btn btn-info" onClick={singupHandler}> Kayıt Et
                </button>

                <h3 className='mt-5'>Giriş Yap</h3>
                {/* <form onSubmit={loginHandler}> */}
                <label style={{ marginRight: "5px" }}>Username:</label>
                <input type="text" value={i_usernameLogin} onChange={(e) => setI_usernameLogin(e.target.value)} style={{ marginRight: "20px" }} />
                <label style={{ marginRight: "5px" }}>Password:</label>
                <input type={showPass ? "text" : "password"} value={i_passwordLogin} onChange={(e) => setI_passwordLogin(e.target.value)} />
                <span onClick={() => setShowPass(current => !current)}> {showPass ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>
                }</span>
                <button type="button" style={{ marginLeft: "20px" }} className="btn btn-info" onClick={loginHandler}> Giriş Yap </button>
                {/* <button type="submit" style={{ marginLeft: "20px" }} className="btn btn-info"> Giriş Yap </button> */}
                {/* </form> */}
                <h3 className='mt-5'>User List <span style={{ backgroundColor: "red", color: "white" }} >Remove</span></h3>
                <div className='w-50'>
                    {data.map((datas, key) => (<>
                        <div key={key} className='row justify-content-center d-flex'>
                            <div className='col-sm-6 my-3 w-25' > {datas.username} </div>
                            <div className='col-sm-6 my-3 w-25'  >  <button type="button" style={{ marginLeft: "20px" }} className="btn btn-danger" onClick={() => removeDatabase(datas.id)}> Kaldır </button></div>
                        </div>  </>))}
                </div>
                <h3 className='mt-5'>User List <span style={{ backgroundColor: "orange", color: "white" }} >Update</span></h3>
                <div className='w-50'>
                    {data.map((datas, key) => (<>
                        <div key={key} className='row justify-content-center d-flex'>
                            <div className='col-sm-6 my-3 w-25' > {datas.username} </div>
                            <div className='col-sm-6 my-3 w-25'  >  <button type="button" style={{ marginLeft: "20px" }} className="btn btn-warning" onClick={() => updateDatabase(datas.id)}> Bildirim Gönder </button></div>
                        </div>  </>))}
                </div>



            </div>


        </>

    )
}

export default AlbumTestPage