import Home from './components/Home';
import SettingsContext, { useSiteContext } from './context/SettingsContext';
import Sidebar from './components/Sidebar';
import classNames from 'classnames';
import Content from './components/Content';
import './assests/css/admin-dashboard.css';
import './assests/css/bootstrap.min.css';
import './assests/css/style.css';
import './assests/css/topbar.css';


function App() {

  return (
    <div>
      <SettingsContext>
        <Content />
      </SettingsContext>
    </div>
  );
}

export default App;
