import React, { useEffect, useState } from 'react'
import depoImgage from '../assests/images/depo-img.jpeg'
import { useSiteContext } from '../context/SettingsContext';
import { ref, set, onValue, remove, update, query, orderByChild, equalTo } from "firebase/database";
import { database } from '../firebase';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import LoginService from './LoginService';
import { toast } from 'react-hot-toast';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import { useParams } from 'react-router-dom';

const DepoDetails = () => {

    const { selectedDepoId, setSelectedDepoId, user, setUser, } = useSiteContext();

    const { name } = useParams();

    const [tabSelected, setTabSelected] = useState("tab1");
    const [tabTechnicSelected, setTabTechnicSelected] = useState("technicTab1");
    const [selectedDepo, setSelectedDepo] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [selectedExecutives, setSelectedExecutives] = useState();
    const [selectedGallery, setSelectedGallery] = useState();
    const [questionsData, setQuestionsData] = useState();
    const [questionText, setQuestionText] = useState();
    const [inputVolume, setInputVolume] = useState();
    const [selectedImage, setSelectedImage] = useState();
    const [userWallet, setUserWallet] = useState();
    const [depoWallet, setDepoWallet] = useState();
    const [volume, setVolume] = useState();
    const [productAttribute, setProductAttribute] = useState();
    const [endDate, setEndDate,] = useState();
    const [startDate, setStartDate,] = useState();
    const [structure, setStructure] = useState();
    const [dates, setDates,] = useState();




    const navigate = useNavigate();

    const modalReserved = () => {
        getDatesHandler();
        document.body.classList.toggle("dp-reserved-open");

    }

    useEffect(() => {
        if (selectedDepoId === undefined) {
            setSelectedDepoId(localStorage.getItem("depoSelected"));
        }
        if (user === undefined) {
            setUser(localStorage.getItem("userId"));
        }
    }, [])
    useEffect(() => {
        if (selectedDepoId) {
            const filteredUser = query(ref(database, "depos"), orderByChild("id"), equalTo(selectedDepoId));
            onValue(filteredUser, (snapshot) => {
                let data_ = snapshot.val();
                let _data_ = Object.keys(data_).map(key => {
                    return data_[key];
                })
                setSelectedDepo(_data_);
                if (_data_[0].depoExecutives) {
                    let executives = Object.keys(_data_[0].depoExecutives).map(key => {
                        return _data_[0].depoExecutives[key];
                    })
                    setSelectedExecutives(executives);
                    setSelectedImage(_data_ && _data_[0].featuredImage.imgURL)
                }
                if (_data_[0].gallery) {
                    let gallery = Object.keys(_data_[0].gallery).map(key => {
                        return _data_[0].gallery[key];
                    })
                    setSelectedGallery(gallery);
                }

            });
            const allQuestions = query(ref(database, "questions"), orderByChild("depoId"), equalTo(selectedDepoId));
            onValue(allQuestions, (snapshot) => {
                let data_ = snapshot.val();
                if (data_ != undefined) {
                    let _data_ = Object.keys(data_).map(key => {
                        return data_[key];
                    })
                    setQuestionsData(_data_);
                } else {
                    setQuestionsData(false);
                }

            });
        }
        if (user) {
            const filteredUser = query(ref(database, "users"), orderByChild("id"), equalTo(user));
            onValue(filteredUser, (snapshot) => {
                let data_ = snapshot.val();
                let _data_ = Object.keys(data_).map(key => {
                    return data_[key];
                });
                setSelectedUser(_data_);
                if (_data_[0].wallet) {
                    let wallet = Object.keys(_data_[0].wallet).map(key => {
                        return _data_[0].wallet[key];
                    })
                    setUserWallet(wallet);
                }
            });
        }
        const structure = query(ref(database, "structure"));
        onValue(structure, (snapshot) => {
            let data_ = snapshot.val();
            let _data_ = Object.keys(data_).map(key => {
                return data_[key];
            });
            setStructure(_data_);
        })
        setVolume(localStorage.getItem("volume"));
        setStartDate(localStorage.getItem("startDate"));
        setEndDate(localStorage.getItem("endDate"));
        setProductAttribute(localStorage.getItem("productAttribute"));
    }, [selectedDepoId, user, localStorage.getItem("userId")]);

    const servicesTab = (evt, tab) => {
        console.log("tab: " + tab);
        setTabSelected(tab);
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-pane");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("nav-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tab).style.display = "block";
        evt.currentTarget.className += " active";
    }
    const modalServices = () => {
        document.body.classList.toggle("dp-services-full-open");
    }

    const technicTab = (evt, tab) => {
        console.log("tab: " + tab);
        setTabTechnicSelected(tab);
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-pane");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("nav-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tab).style.display = "block";
        evt.currentTarget.className += " active";
    }
    const modalTechnic = () => {
        document.body.classList.toggle("dp-technic-full-open");
    }

    const modalSecurity = () => {
        document.body.classList.toggle("dp-security-full-open");
    }


    const questionSend = () => {
        const createId = nanoid();
        update(ref(database, "questions/" + createId), { depoId: selectedDepoId, userId: user, id: createId, question: questionText, status: "2", userName: selectedUser[0].name, depoName: selectedDepo[0].name });
        setQuestionText("");
    }

    const reserveHandler = () => {
        const total = (selectedDepo && selectedDepo[0].price) * (volume && parseInt(volume)) * (dates && dates.length);
        console.log(total - ((total / 100) * (structure && structure[0])));
        if (userWallet && userWallet[0] < total) {
            toast.error("Bakiye yetersiz 🚫");
        } else {
            const createId = nanoid();
            set(ref(database, "rents/" + createId), {
                id: createId,
                date: new Date().getTime(),
                userPrice: total,
                depoPrice: total - ((total / 100) * (structure && structure[0])),
                adminPrice: ((total / 100) * (structure && structure[0])),
                status: "Onay Bekliyor",
                volume: volume && parseInt(volume),
                currentVolume: volume && parseInt(volume),
                depoId: selectedDepoId,
                userId: selectedUser && selectedUser[0].id,
                userName: selectedUser && selectedUser[0].name,
                depoName: selectedDepo && selectedDepo[0].name,
                startDate: dates && dates[0],
                endDate: dates && dates[dates.length - 1],
                productAttribute: productAttribute && productAttribute,


            })
            dates.map((date) => {
                set(ref(database, "rents/" + createId + "/dates/" + date), {
                    id: date,
                    volume: parseInt(volume),

                })
            })
            set(ref(database, "users/" + localStorage.getItem("userId") + "/wallet/"), {
                currentWallet: userWallet && userWallet[0] - total,
            })
            document.body.classList.toggle("dp-reserved-open");
        }
    };

    const getDatesHandler = () => {

        const d1 = new Date(startDate);
        const d2 = new Date(endDate);
        const date = new Date(d1.getTime());

        date.setDate(date.getDate());
        const datesHandler = [];
        while (date <= d2) {
            datesHandler.push(new Date(date).ddmmyyyy());
            date.setDate(date.getDate() + 1);
            setDates(datesHandler);
        }
    }

    Date.prototype.ddmmyyyy = function () {
        var mm = this.getMonth() + 1; // getMonth() is zero-based
        var dd = this.getDate();

        return [(dd > 9 ? '' : '0') + dd, "-",
        (mm > 9 ? '' : '0') + mm, "-",
        this.getFullYear()].join('');
    };


    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const popupSelectedImage = () => {

        document.body.classList.toggle("dp-selected-image-open");
    }


    return (
        <>

            <div className="container-fluid pt-4 px-4">
                <div className="row g-4">
                    <div className=' bg-secondary rounded h-100 p-4'>
                        <div className='row g-4'>
                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                                <span style={{ display: "flex" }}> <h5> {selectedDepo && selectedDepo[0].name}</h5> <p style={{ marginLeft: "10px" }}> Günlük birim alan ücreti:  {selectedDepo && selectedDepo[0].price}₺ </p></span>
                                <p><i className='fa fa-map'></i> {selectedDepo && selectedDepo[0].location} </p>
                            </div>
                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12'>

                            </div>
                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                                <div className=" d-flex col align-items-center justify-content-end mr-1">
                                    <button type="button" className="btn btn-info" onClick={modalReserved}>Hesapla & Rezerve Et</button>
                                </div>
                            </div>
                        </div>
                        <div className='row g-4'>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 float-start'>
                                <div className="row g-4 dp-big-image-canvas">
                                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                        <div className='row position-relative'>
                                            <div className="col-3 position-absolute dp-big-image-button" onClick={popupSelectedImage}>
                                                <i className='fa fa-search-plus'></i><span> Büyük Fotoğraf</span></div>
                                        </div>
                                        <div className="rov">
                                            <img className='shadow dp-big-image' src={selectedImage ? selectedImage : selectedDepo && selectedDepo[0].featuredImage}></img>
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-4 mt-1 mb-3">
                                    <Slider {...settings}>
                                        {selectedDepo && selectedDepo[0].featuredImage ? <img className='w-100' src={selectedDepo && selectedDepo[0].featuredImage} onClick={() => setSelectedImage(selectedDepo && selectedDepo[0].featuredImage)}></img> : null}
                                        {selectedGallery && selectedGallery.map((image, index) => <>
                                            <div className='cursor-pointer'>
                                                <img className='w-100 mb-5 curser-pointer' src={image.imgURL} onClick={() => setSelectedImage(image.imgURL)}></img>
                                            </div>
                                        </>)}

                                    </Slider>
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 float-start text-center'>
                                <div className='row g-4'>
                                    <div className=" col align-items-center mr-1">
                                        <button type="button" className="btn btn-outline-info" onClick={modalServices}>Hizmetler</button>
                                    </div>
                                    <div className=" col align-items-center mr-1">
                                        <button type="button" className="btn btn-outline-info" onClick={modalTechnic}>Teknik Özellikler</button>
                                    </div>
                                    <div className=" col align-items-center mr-1">
                                        <button type="button" className="btn btn-outline-info" onClick={modalSecurity}>Güvenlik Önlemleri</button>
                                    </div>
                                </div>
                                <div className='row g-4 mt-3'>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                        {selectedExecutives?.map((exe, index) => <>
                                            <div className=' bg-secondary rounded h-100 p-4'>
                                                <h5 className='text-center mb-3'><i className='fa fa-user'></i> Depo Yetkilisi</h5>
                                                <div className='row text-start'>
                                                    <h6> {exe.name} </h6>
                                                    <p> {exe.phone} </p>
                                                    <p> {exe.mail} </p>
                                                    <p> {exe.area} </p>
                                                    {/* <p className='executive-languages'>
                                                    <span>TR</span>
                                                    <span>EN</span>
                                                </p> */}
                                                </div>
                                            </div>
                                        </>)}

                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                        <div className=' bg-secondary rounded h-100 p-4 text-start'>
                                            <h5 className='text-center mb-3'><i className='fa fa-clock'></i> Çalışma Saatleri</h5>
                                            <p>Hafta İçi: {selectedDepo && selectedDepo[0].wHMonFriStart} / {selectedDepo && selectedDepo[0].wHMonFriEnd} </p>
                                            <p>Cumartesi: {selectedDepo && selectedDepo[0].wHSaturdayStart} / {selectedDepo && selectedDepo[0].wHSaturdayEnd} </p>
                                            <p>Pazar:  {selectedDepo && selectedDepo[0].wHSunday ? <>{selectedDepo && selectedDepo[0].wHSundayStart} / {selectedDepo && selectedDepo[0].wHSundayEnd}</> : <>Kapalı</>} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row g-4 mt-3'>
                                    <iframe src={selectedDepo && selectedDepo[0].map} className='w-100 h-30vh' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                        <div className='row g4 mt-5'>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 dp-questions'>
                                <h5>Depoya Sorulan Sorular</h5>
                                {questionsData && questionsData?.filter(a => a.status === "0").map((question, index) => <>
                                    <div className='row g-4 mt-3 p-4 pb-0 pt-0 bg-secondary rounded dp-comment'>
                                        <h6> #{index + 1} </h6>
                                        <p className='mt-0'> {question.question} </p>
                                        <div className='row g-4 mt-0 p-4 pb-0 pt-0 pb-0 dp-comment'>
                                            <h6>Yanıt: Depo Sahibi</h6>
                                            <p className='mt-0'> {question.answer} </p>
                                        </div>
                                    </div>
                                </>)}

                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-lg-0 mt-md-0 mt-sm-5'>
                                <h5 className='text-center'>Depoya Soru Sor</h5>
                                <div className=' bg-secondary rounded mt-3 p-4 text-center'>
                                    <p>Depoya sorularınızı buradan sorabilirsiniz.</p>
                                    <div className="form-floating">
                                        <textarea className="form-control" disabled={!user} id="floatingTextarea" style={{ height: 150 }} value={questionText} onChange={(e) => setQuestionText(e.target.value)} ></textarea>
                                        <label for="floatingTextarea">{user ? <p>Sorunuzu Yazınız.</p> : <p>Soru sormak için giriş yapmanız gerekmektedir.</p>}</label>
                                    </div>
                                    <div className=" d-flex col align-items-center justify-content-center mt-3">
                                        <button type="button" className="btn btn-info" disabled={!user} onClick={questionSend} >Soru Sor</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i> </a>

            {/* Modal Start */}

            <div className="dp-services-full-background" onClick={modalServices}></div>
            <div className="dp-services-full-modal">
                <h3 className='text-center'>Hizmetler</h3>
                <span onClick={modalServices} className="dp-services-full-modal-close">X</span>

                <div className='row dp-feature-sticky'>
                    <ul className="nav nav-pills col-sm-12 " id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" onClick={(e) => servicesTab(e, 'tab1')}>Verilen Hizmetleri</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" onClick={(e) => servicesTab(e, 'tab2')}>Hizmet Verilen Ürünler</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-contact-tab" onClick={(e) => servicesTab(e, 'tab3')}>Sağlanan İmkanlar</button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content mt-3" id="pills-tabContent">
                    <div className="tab-pane active show fade pill" id="tab1">
                        <div className='row g-4'>
                            <div className='h-100 p-4 pt-0  col-md-4'>
                                <h6>Katma Değer Hizmetler</h6>
                                <div>
                                    <label><i className='fa fa-check'></i> Ürün alma</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Paletleme</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Palet değişim</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Streçleme</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Palet çemberleme</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Pahalı ürün için özel alan</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Konteyner yıkama ve temizleme</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Konteyner tamirat ve onarım</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Muşamba örtülmesi</label>
                                </div>
                            </div>
                            <div className='h-100 p-4 pt-0  col-md-4'>
                                <h6>Nakliye Hizmetleri</h6>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Şehir içi nakliye</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Şehir içi dağıtım</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Şehir içi motor kurye</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Şehir içi kargo</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Şehirler arası nakliye</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Şehirler arası kargo</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Uluslararası nakliye</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Uluslararası kargo</label>
                                </div>
                            </div>
                            <div className='h-100 p-4 pt-0  col-md-4'>
                                <h6>Temel Hizmetler</h6>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Araçtan boşaltma</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Mal kabul</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Stoklama yerine koyma</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Stoklama</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Sayım</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Stoklama yerinden alınması</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Sevkiyata hazırlama (varsa dokümanların hazırlanması)</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Araca yükleme</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane show fade pill" id="tab2">
                        <div className='row g-4'>
                            <div className='h-100 p-4 pt-0 col-md-4'>
                                <div>
                                    <label><i className='fa fa-check'></i> Bahçe malzemeleri</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Boş konteyner</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Demir ve çelik</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Endüstriyel ürün (kimyasal)</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Endüstriyel ürün (maden)</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Endüstriyel ürün (makine)</label>
                                </div>
                            </div>
                            <div className='h-100 p-4 pt-0 col-md-4'>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Eşya (ofis)</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Eşya (ev)</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Gabari dışı ürün</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Hurda malzemeler</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> İnşaat malzemeleri</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> İş makinesi</label>
                                </div>
                            </div>
                            <div className='h-100 p-4 pt-0 col-md-4'>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Otomotiv (araç)</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Rulo sac</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Tarım ürünleri</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Tekne</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Temizlik ürünleri (endüstriyel, ev)</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Uzun Ürün</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane show fade pill" id="tab3">
                        <div className='row g-4'>
                            <div className='h-100 p-4 pt-0 col-md-4'>
                                <div>
                                    <label><i className='fa fa-check'></i> Ofis</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Yemek</label>
                                </div>
                            </div>
                            <div className='h-100 p-4 pt-0 col-md-4'>
                                <div>
                                    <label><i className='fa fa-check'></i> Çalışan servisi</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> İş emniyeti ekipmanları (KKD)</label>
                                </div>
                            </div>
                            <div className='h-100 p-4 pt-0 col-md-4'>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> İnternet erişim & Wi-Fi</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Servis alanı</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="dp-technic-full-background" onClick={modalTechnic}></div>
            <div className="dp-technic-full-modal">
                <h3 className='text-center'>Teknik Özellikler</h3>
                <span onClick={modalTechnic} className="dp-technic-full-modal-close">X</span>

                <div className='row dp-feature-sticky'>
                    <ul className="nav nav-pills col-sm-12 " id="pills-tab1" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab1" onClick={(e) => technicTab(e, 'technicTab1')}>Teknik Bilgiler</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab1" onClick={(e) => technicTab(e, 'technicTab2')}>Sertifikalar ve Belgeler</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-contact-tab1" onClick={(e) => technicTab(e, 'technicTab3')}>Teknolojiler</button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content mt-3" id="pills-tabContent1">
                    <div className="tab-pane active show fade pill" id="technicTab1">
                        <div className='row g-4'>
                            <div className='h-100 p-4 pt-0  col-md-4'>
                                <div>
                                    <label><i className='fa fa-check'></i> Açık stoklama alanı (toprak zemin)</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Açık stoklama alanı (mıcır ve çakıl zemin)</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Açık stoklama alanı (beton zemin)</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Açık stoklama alanında sundurma</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Gabari dışı ürün elleçleme</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> 5 tona kadar ağır ürünler için vinç</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> 10 tona kadar ağır ürünler için vinç</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> 10 tondan fazla ağır ürünler için vinç</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Konteyner istifleyici (stacker)</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Park alanı (kamyon, TIR)</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Araç kantarı (kamyon, TIR)</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Konsol kollu raf sistemi</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Rulo sac için raf sistemi</label>
                                </div>
                            </div>
                            <div className='h-100 p-4 pt-0  col-md-4'>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Silo tank (hububat, tahıl vs. dökme ürünler)</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Sıvı ürün için tank</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Mobil yükleme rampası</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Endüstriyel tartı (palet, koli vs.)</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Yanıcı ürünler için alan</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Yanıcı haricindeki tehlikeli ürünler için alan</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Pahalı ürünler için özel alan</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> A tipi Açık Gümrüklü alana sahip</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> A tipi Kapalı Gümrüklü alana sahip</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Geçici Depolama alanına (açık) sahip</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Geçici Depolama alanına (kapalı) sahip</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> İade ürün alanı</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Karantina ürün alanı</label>
                                </div>
                            </div>
                            <div className='h-100 p-4 pt-0  col-md-4'>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Zemin taşıma kapasitesi yeterli</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Jeneratör</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Forklift ataşmanı (rulo sac)</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Forklift ataşmanı (varil)</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Giriş, ürün hareketi için uygun</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Paletli ürünler için uygun</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Giriş, forklift hareketi için uygun</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Transpalet</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Forklift</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Konteyner yıkama ve temizleme alanı</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Konteyner tamirat ve onarım alanı</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Gece çalışmak için aydınlatma</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Forklift (ağır paletli ürünler)</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane show fade pill" id="technicTab2">
                        <div className='row g-4'>
                            <div className='h-100 p-4 pt-0 col-md-4'>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> ISO 9001</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> ISO 14001</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> ISO 45001</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> ISO 10002</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> ISO 27001</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> ISO 22301</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> ISO 28000</label>
                                </div>
                            </div>
                            <div className='h-100 p-4 pt-0 col-md-4'>
                                <div>
                                    <label><i className='fa fa-check'></i> OHSAS 18001</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Tehlikeli ürünler için izinler</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Yanıcı ürünler için izinler</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> HAZMAT</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> YYS (AEO)</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> C-TPAT</label>
                                </div>
                            </div>
                            <div className='h-100 p-4 pt-0 col-md-4'>
                                <div>
                                    <label><i className='fa fa-check'></i> L Belgesi</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> K Belgesi</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> M Belgesi</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> N Belgesi</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> P Belgesi</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> TİO Belgesi</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane show fade pill" id="technicTab3">
                        <div className='row g-4'>
                            <div className='h-100 p-4 pt-0 col-md-4'>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Depo Yazılımı (WMS) kullanılır</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> ERP kullanılır</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> El terminali kullanılır</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Barkod yazıcı kullanılır</label>
                                </div>
                            </div>
                            <div className='h-100 p-4 pt-0 col-md-4'>
                                <div>
                                    <label><i className='fa fa-check'></i> Bulut sistemi (teknolojisi)</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> RFID kullanımı</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> EDI entegrasyonu ve EDIFACT uyum</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Farklı depo yazılımları ile entegrasyon</label>
                                </div>
                            </div>
                            <div className='h-100 p-4 pt-0 col-md-4'>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Farklı ERPler ile entegrasyon</label>
                                </div>
                                <div>
                                    <label><i className='fa fa-check'></i> Fiber optik kablo altyapısı</label>
                                </div>
                                <div>
                                    <label className='text-disable'><i className='fa fa-times'></i> Bilgi Güvenliği altyapısı ve uygulaması</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="dp-security-full-background" onClick={modalSecurity}></div>
            <div className="dp-security-full-modal">
                <h3 className='text-center'>Güvenlik Önlemleri</h3>
                <span onClick={modalSecurity} className="dp-security-full-modal-close">X</span>
                <div className='row g-4'>
                    <div className='h-100 p-4 pt-0  col-md-4'>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Güvenlik kamerası (rampa, yükleme ve boşaltma alanı)</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Güvenlik kamerası (ürün kabul)</label>
                        </div>
                        <div>

                            <label><i className='fa fa-check'></i> Güvenlik kamerası (stoklama alanı)</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Güvenlik kamerası (dış alan)</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Güvenlik kamerası (perimetre)</label>
                        </div>
                        <div>

                            <label><i className='fa fa-check'></i> Güvenlik kamerası (girişi ve çevresini gören)</label>
                        </div>
                        <div>

                            <label><i className='fa fa-check'></i> Girişte güvenlik binası ve bariyer</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Giriş ve çıkış için güvenlik kontrolü</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Duvar veya tel çit ile çevrili</label>
                        </div>
                        <div>

                            <label><i className='fa fa-check'></i> Güvenlik personeli (7/24)</label>
                        </div>
                        <div>
                            <label className='text-disable'><i className='fa fa-times'></i> PEST Kontrol</label>
                        </div>
                        <div>

                            <label><i className='fa fa-check'></i> Sağlık uygulaması</label>
                        </div>
                    </div>
                    <div className='h-100 p-4 pt-0  col-md-4'>
                        <div>

                            <label><i className='fa fa-check'></i> Göz duşu</label>
                        </div>
                        <div>

                            <label><i className='fa fa-check'></i> Vücut duşu</label>
                        </div>
                        <div>

                            <label><i className='fa fa-check'></i> Kimyasal atık veya döküntü için toplama yeri</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Depreme karşı güvenli</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Yangın söndürme tüpleri</label>
                        </div>
                        <div>

                            <label><i className='fa fa-check'></i> Dedektör ve alarm sistemi (alev)</label>
                        </div>
                        <div>

                            <label><i className='fa fa-check'></i> Dedektör ve alarm sistemi (hırsızlık ve izinsiz giriş)</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Dedektör ve alarm sistemi (PIR, hareket)</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Acil durum tahliye uygulaması</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> İnşaat için kullanılan malzemeler yangına karşı uygun ve onaylı</label>
                        </div>
                        <div>
                            <label><i className='fa fa-check'></i> Yanıcı ürün alanında ex-proof alet, ekipman, malzeme kullanılır</label>
                        </div>
                    </div>
                    <div className='h-100 p-4 pt-0  col-md-4'>
                        <div>

                            <label><i className='fa fa-check'></i> Kriminal olayların olmadığı güvenli bölge</label>
                        </div>
                        <div>

                            <label><i className='fa fa-check'></i> İtfaiye yangın uygunluk raporu ve izni</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Perimetredeki çöpler, düzenli toplanır</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Polis veya bekçi düzenli devriye güzergahında</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Pencereler, demir parmaklık ile korunur</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> Perimetre için gece aydınlatması</label>
                        </div>
                        <div>

                            <label className='text-disable'><i className='fa fa-times'></i> İzinsiz ve kontrolsüz girişi ve çıkışı kolaylaştıran ağaç veya direk yok</label>
                        </div>
                        <div>

                            <label><i className='fa fa-check'></i> İzinsiz ve kontrolsüz ürün çıkışına önlem</label>
                        </div>
                        <div>

                            <label><i className='fa fa-check'></i> Bilgi Güvenliği altyapısı ve uygulaması</label>
                        </div>
                        <div>

                            <label><i className='fa fa-check'></i> Ekstra güvenlik uygulaması</label>
                        </div>
                        <div>
                            <label className='text-disable'><i className='fa fa-times'></i> Talebe göre özel güvenlik uygulaması yapılabilir</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="dp-reserved-background" onClick={modalReserved}></div>
            <div className="dp-reserved-modal text-center">
                {localStorage.getItem("userId") ? <>
                    <h3 className=''>Hesapla & Rezerve Et</h3>
                    <span onClick={modalReserved} className="dp-reserved-modal-close">X</span>
                    <p>
                        Kiralama işlemini toplam hacmi kontrol ederek onaylayınız.
                    </p>

                    <div className="row mb-3 pl-2 justify-content-start">
                        <label htmlFor="inputEmail3" className="col-md-2 text-start col-form-label">Fiyat:</label>
                        <div className="col-md-3 align-self-center text-start">
                            {selectedDepo && selectedDepo[0].price} ₺ / m<sup>3</sup> Gün
                        </div>
                        <div className="col-md-2">
                        </div>
                    </div>
                    <div className="row mb-3 pl-2 justify-content-start">
                        <label className="col-md-2 text-start col-form-label">Tarih:</label>
                        <div className="col-md-3 align-self-center text-start">
                            {dates && dates[0]} -  {dates && dates[dates.length - 1]} ({dates && dates.length} Gün)
                        </div>
                    </div>
                    <div className="row mb-3 pl-2 justify-content-start">
                        <label className="col-md-2 text-start col-form-label">Hacim:</label>
                        <div className="col-md-3 align-self-center text-start">
                            {volume && volume}m<sup>3</sup>
                        </div>
                    </div>
                    <div className="row mb-3 pl-2 justify-content-start">
                        <label className="col-md-2 text-start col-form-label">Toplam:</label>
                        <div className="col-md-2 align-self-center text-start">
                            <h6 className='mb-0'> {(selectedDepo && selectedDepo[0].price) * (volume && parseInt(volume)) * (dates && dates.length)} ₺</h6>
                            {console.log(selectedDepo && selectedDepo[0].price)}
                            {console.log(volume && parseInt(volume))}
                            {console.log(dates && dates.length * (volume && parseInt(volume)) * (selectedDepo && selectedDepo[0].price))}
                        </div>
                    </div>

                    <button type="button" className="btn btn-success m-2" onClick={reserveHandler} >Onayla</button>
                    <button type="button" className="btn btn-danger m-2" onClick={modalReserved} >İptal Et</button>
                </> : <LoginService />}
            </div>


            <div className="dp-selected-image-background" onClick={popupSelectedImage}></div>
            <div className="dp-selected-image-modal text-center p-0">
                <span onClick={popupSelectedImage} className="dp-selected-image-modal-close">X</span>
                <div className="row justify-content-start">
                    <img className='w-100 mh-60 object-fit-contain' src={selectedImage}></img>
                </div>
            </div>

            {/* Modal End */}
            {/* <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 float-start'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3005.4372114260473!2d28.635449076168527!3d41.1249806713343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b55788048ba861%3A0xa7524d37e155b7e5!2sDepoglobal%20Logistics%20Antrepo!5e0!3m2!1str!2str!4v1689004337984!5m2!1str!2str" className='w-100 h-50vh' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div> */}
        </>
    )
}

export default DepoDetails