import React, { useState, useEffect } from 'react'
import { ref, set, onValue, remove, update, query, orderByChild, equalTo } from "firebase/database";
import { database } from '../firebase';
import { useSiteContext } from '../context/SettingsContext';
import ReactPaginate from 'react-paginate';

const Rent = () => {

    const { selectedDepoId, setSelectedDepoId } = useSiteContext();

    const [data, setData] = useState();
    const [volume, setVolume] = useState();
    const [selectedDepo, setSelectedDepo] = useState();
    const [selectedRents, setSelectedRents] = useState();
    const [itemOffsetApproval, setItemOffsetApproval] = useState(0);
    const [itemOffsetConfirmed, setItemOffsetConfirmed] = useState(0);
    const [itemOffsetCanceled, setItemOffsetCanceled] = useState(0);
    const [listNumberApproval, setListNumberApproval] = useState(5);
    const [listNumberConfirmed, setListNumberConfirmed] = useState(5);
    const [listNumberCanceled, setListNumberCanceled] = useState(5);
    const [approvalRent, setApprovalRent] = useState();
    const [confirmedRent, setConfirmedRent] = useState();
    const [canceledRent, setCanceledRent] = useState();
    const [user, setUser] = useState();
    const [rentsInfo, setRentsInfo] = useState();
    const [cancelRentId, setCancelRentId] = useState();
    const [cancelRentPrice, setCancelRentPrice] = useState();



    useEffect(() => {
        if (selectedDepoId === undefined) {
            setSelectedDepoId(localStorage.getItem("depoSelected"));
        }
    }, []);
    useEffect(() => {
        const selectedUser = query(ref(database, "users/" + localStorage.getItem("userId")));
        onValue(selectedUser, (snapshot) => {
            let data_ = snapshot.val();
            setUser(data_);
        })
        const userRents = query(ref(database, "rents"), orderByChild("userId"), equalTo(localStorage.getItem("userId")));
        onValue(userRents, (snapshot) => {
            let data_ = snapshot.val();
            let _data_ = Object.keys(data_).map(key => {
                return data_[key];
            });
            setApprovalRent(_data_ && _data_.filter(e => e.status === "Onay Bekliyor"));
            setConfirmedRent(_data_ && _data_.filter(e => e.status === "Onaylandı"));
            setCanceledRent(_data_ && _data_.filter(e => e.status === "İptal Edildi"));
        })
    }, []);


    // Approval Pagination
    const itemsPerPageApproval = listNumberApproval;
    const itemsApproval = approvalRent && approvalRent;
    const endOffsetApproval = itemOffsetApproval + itemsPerPageApproval;
    console.log(`Loading items from ${itemOffsetApproval} to ${endOffsetApproval}`);
    const currentItemsApproval = itemsApproval?.slice(itemOffsetApproval, endOffsetApproval);
    const pageCountApproval = Math.ceil(itemsApproval?.length / itemsPerPageApproval);


    const handlePageClickApproval = (event) => {
        const newOffset = (event.selected * itemsPerPageApproval) % itemsApproval.length;
        setItemOffsetApproval(newOffset);
    };

    // Confirmed Pagination
    const itemsPerPageConfirmed = listNumberConfirmed;
    const itemsConfirmed = confirmedRent && confirmedRent;
    const endOffsetConfirmed = itemOffsetConfirmed + itemsPerPageConfirmed;
    const currentItemsConfirmed = itemsConfirmed?.slice(itemOffsetConfirmed, endOffsetConfirmed);
    const pageCountConfirmed = Math.ceil(itemsConfirmed?.length / itemsPerPageConfirmed);

    const handlePageClickConfirmed = (event) => {
        const newOffset = (event.selected * itemsPerPageConfirmed) % itemsConfirmed.length;
        setItemOffsetConfirmed(newOffset);
    };

    // Canceled Pagination
    const itemsPerPageCanceled = listNumberCanceled;
    const itemsCanceled = canceledRent && canceledRent;
    const endOffsetCanceled = itemOffsetCanceled + itemsPerPageCanceled;
    const currentItemsCanceled = itemsCanceled?.slice(itemOffsetCanceled, endOffsetCanceled);
    const pageCountCanceled = Math.ceil(itemsCanceled?.length / itemsPerPageCanceled);


    const handlePageClickCanceled = (event) => {
        const newOffset = (event.selected * itemsPerPageCanceled) % itemsCanceled.length;
        setItemOffsetCanceled(newOffset);
    };

    const rentDetailModal = () => {
        document.body.classList.toggle("dp-rent-detail-open");
    }

    console.log()

    const depoRentsTab = (evt, tab) => {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-pane");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("rent-nav-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tab).style.display = "block";
        evt.currentTarget.className += " active";
    }

    const cancelRent = (id, price) => {
        setCancelRentId(id);
        setCancelRentPrice(price);
        document.body.classList.toggle("dp-cancel-rent-open");


    }

    const cancelRentHandler = () => {
        update(ref(database, "rents/" + cancelRentId), { status: "İptal Edildi" })
        update(ref(database, "users/" + localStorage.getItem("userId") + "/wallet"), { currentWallet: (user && user.wallet.currentWallet) + parseInt(cancelRentPrice) });
        document.body.classList.toggle("dp-cancel-rent-open");
    }



    return (
        <>


            <div className="container-fluid pt-4 px-4">
                <div className="row g-4">

                    <div className="col-12">
                        <div className="bg-secondary rounded h-100 p-4">
                            <h6 className="mb-4">Kiralama Geçmişi</h6>


                            <div className='row dp-feature-sticky'>
                                <ul className="nav nav-pills col-sm-10 " id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="rent-nav-link nav-link active" id="pills-home-tab" onClick={(e) => depoRentsTab(e, 'tab1')}>Onay Bekleyen İşlemler</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="rent-nav-link nav-link" id="pills-profile-tab" onClick={(e) => depoRentsTab(e, 'tab2')}>Depodaki İşlemler</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="rent-nav-link nav-link" id="pills-profile-tab" onClick={(e) => depoRentsTab(e, 'tab3')}>İptal Edilen / Reddedilen İşlemler</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content mt-3" id="pills-tabContent">
                                <div className="tab-pane active show fade pill" id="tab1">
                                    <div className="table-responsive">

                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">İşlem id</th>
                                                    <th scope="col">Toplam Hacim</th>
                                                    <th scope="col">Güncel Hacim</th>
                                                    <th scope="col">Ürün Niteliği</th>
                                                    <th scope="col">Fiyat</th>
                                                    <th scope="col">Başlangıç Tarihi</th>
                                                    <th scope="col">Bitiş Tarihi</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                {currentItemsApproval &&
                                                    currentItemsApproval.map((rent, index) => (
                                                        <tr className=' align-middle'>
                                                            <td style={{ textTransform: "Uppercase" }} >#{rent.id.substr(0, 5)} </td>
                                                            <td> {rent.volume}m<sup>3</sup></td>
                                                            <td> {rent.currentVolume}m<sup>3</sup></td>
                                                            <td> {rent.productAttribute} </td>
                                                            <td> {rent.userPrice}₺ </td>
                                                            <td> {rent.startDate} </td>
                                                            <td> {rent.endDate}</td>
                                                            <td> <button className='btn btn-outline-danger' onClick={() => cancelRent(rent.id, rent.userPrice)}> İptal Et </button></td>
                                                        </tr>
                                                    ))}

                                            </tbody>
                                        </table>
                                    </div>
                                    <nav aria-label="Page navigation example" className='mt-3'>
                                        <ul class="pagination justify-content-end">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="Sonraki"
                                                onPageChange={handlePageClickApproval}
                                                pageRangeDisplayed={5}
                                                pageCount={pageCountApproval}
                                                previousLabel="Önceki"
                                                renderOnZeroPageCount={null}
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"
                                            />
                                        </ul>
                                    </nav>

                                </div>

                                <div className="tab-pane show fade pill" id="tab2">
                                    <div className="table-responsive">
                                        {/* <div className="input-group mb-3 mt-3">
                                            <input type="text" className="form-control" placeholder="Ara..." />
                                            <button type="input-group-text button" className="btn btn-info">Ara</button>
                                        </div> */}
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">İşlem id</th>
                                                    <th scope="col">Toplam Hacim</th>
                                                    <th scope="col">Güncel Hacim</th>
                                                    <th scope="col">Ürün Niteliği</th>
                                                    <th scope="col">Fiyat</th>
                                                    <th scope="col">Başlangıç Tarihi</th>
                                                    <th scope="col">Bitiş Tarihi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsConfirmed &&
                                                    currentItemsConfirmed.map((rent, index) => (
                                                        <tr className=' align-middle'>
                                                            <td style={{ textTransform: "Uppercase" }} >#{rent.id.substr(0, 5)} </td>
                                                            <td> {rent.volume}m<sup>3</sup></td>
                                                            <td> {rent.currentVolume}m<sup>3</sup></td>
                                                            <td> {rent.productAttribute} </td>
                                                            <td> {rent.userPrice}₺ </td>
                                                            <td> {rent.startDate} </td>
                                                            <td> {rent.endDate}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <nav aria-label="Page navigation example" className='mt-3'>
                                        <ul class="pagination justify-content-end">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="Sonraki"
                                                onPageChange={handlePageClickConfirmed}
                                                pageRangeDisplayed={5}
                                                pageCount={pageCountConfirmed}
                                                previousLabel="Önceki"
                                                renderOnZeroPageCount={null}
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"
                                            />
                                        </ul>
                                    </nav>
                                </div>

                                <div className="tab-pane show fade pill" id="tab3">
                                    <div className="table-responsive">
                                        {/* <div className="input-group mb-3 mt-3">
                                            <input type="text" className="form-control" placeholder="Ara..." />
                                            <button type="input-group-text button" className="btn btn-info">Ara</button>
                                        </div> */}
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">İşlem id</th>
                                                    <th scope="col">Toplam Hacim</th>
                                                    <th scope="col">Güncel Hacim</th>
                                                    <th scope="col">Ürün Niteliği</th>
                                                    <th scope="col">Fiyat</th>
                                                    <th scope="col">Başlangıç Tarihi</th>
                                                    <th scope="col">Bitiş Tarihi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsCanceled &&
                                                    currentItemsCanceled.map((rent, index) => (
                                                        <tr className=' align-middle'>
                                                            <td style={{ textTransform: "Uppercase" }} >#{rent.id.substr(0, 5)} </td>
                                                            <td> {rent.volume}m<sup>3</sup></td>
                                                            <td> {rent.currentVolume}m<sup>3</sup></td>
                                                            <td> {rent.productAttribute} </td>
                                                            <td> {rent.userPrice}₺ </td>
                                                            <td> {rent.startDate} </td>
                                                            <td> {rent.endDate}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <nav aria-label="Page navigation example" className='mt-3'>
                                        <ul class="pagination justify-content-end">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="Sonraki"
                                                onPageChange={handlePageClickCanceled}
                                                pageRangeDisplayed={5}
                                                pageCount={pageCountCanceled}
                                                previousLabel="Önceki"
                                                renderOnZeroPageCount={null}
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"
                                            />
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>


            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>

            <div className="dp-rent-detail-background" onClick={rentDetailModal}></div>
            <div className="dp-rent-detail-modal text-center">
                <h3>İşlem Detayı</h3>
                <span onClick={rentDetailModal} className="dp-rent-detail-modal-close">X</span>
                <div className="row mb-3 text-start mt-5">
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <p>İşlem Id<br /><b>#U1YMP</b></p>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <p>İşlem Sahibi<br /><b>Yiğit Can Derya</b></p>
                    </div>
                </div>
                <div className="row mb-3 text-start">
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <p>Telefon<br /><b>0555 555 55 55</b></p>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <p>E-Posta Adresi<br /><b>yigit@depoglobal.com</b></p>
                    </div>
                </div>
                <div className="row text-start">
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <p>Kullanılan Hacim<br /><b>15m<sup>3</sup></b></p>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                        <p>İşlem Başlangıç Tarihi<br /><b>26.07.2023</b></p>
                    </div>
                </div>
            </div>
            <div className="dp-cancel-rent-background" onClick={cancelRent}></div>
            <div className="dp-cancel-rent-modal text-center">
                <span onClick={cancelRent} className="dp-cancel-rent-modal-close">X</span>
                <h3>Kiralama İşlemini İptal Et</h3>
                <p className='mt-3'>Kiralama işlemini iptal etmek üzeresiniz.</p>
                <button type="button" className="btn btn-success m-2" onClick={cancelRentHandler}>İşlemi İptal Et</button>
                <button type="button" className="btn btn-danger m-2" onClick={cancelRent} >Kapat</button>

            </div>
        </>
    )
}

export default Rent