import React, { useEffect, useState } from "react";
import { ref, set, onValue, remove, update, query, orderByChild, equalTo } from "firebase/database";
import bcrypt from 'bcryptjs'
import { database } from '../firebase';
import { useNavigate } from "react-router-dom";

const Logout = () => {

    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem("userId")
        navigate("/login");
    }, [])


    return (
        <>
        </>

    );
};

export default Logout;
