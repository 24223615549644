import React from "react";
import RegisterService from "./RegisterService";

const Register = () => {

    return (

        <>
            <div className="container-fluid">
                <div className="row h-100 align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                        <RegisterService />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register