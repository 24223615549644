import React, { useEffect, useState } from 'react'
import depoImgage from '../assests/images/depo-img.jpeg'
import { useSiteContext } from '../context/SettingsContext'
import { useNavigate } from 'react-router-dom';
import { ref, set, onValue, remove, update, query, orderByChild, equalTo } from "firebase/database";
import { database } from '../firebase';
import Search from './Search';

const Home = () => {



    return (
        <Search />
    )
}

export default Home